import React from 'react';
import './red-style.css';

const WhiteLogo = ({ className }) => {
  return (
    <svg className={ className } xmlns="http://www.w3.org/2000/svg" width="54.18mm" height="30.48mm" version="1.1" viewBox="0 0 16256 9144">
      <g id="Camada_x0020_1">
        <metadata id="CorelCorpID_0Corel-Layer"/>
        <g id="_1784873184928">
        <path className="fil0" d="M8942.52 5503.51l6064.25 0c275.25,0 500.45,-225.21 500.45,-500.46l0 -1493.34c0,-275.28 -225.25,-500.54 -500.57,-500.54l-6064.13 0c-67.02,0 -121.37,54.32 -121.37,121.38l0 2251.59c0,67.02 54.35,121.37 121.37,121.37z"/>
        <path className="fil0 str0" d="M8942.52 5503.51l6064.25 0c275.25,0 500.45,-225.21 500.45,-500.46l0 -1493.34c0,-275.28 -225.25,-500.54 -500.57,-500.54l-6064.13 0c-67.02,0 -121.37,54.32 -121.37,121.38l0 2251.59c0,67.02 54.35,121.37 121.37,121.37z"/>
        <path className="fil0" d="M2021.17 3724.82l0 1069.02c0,10.44 -3.69,19.35 -10.95,26.6 -7.26,7.26 -16.04,10.96 -26.49,10.96l-235.14 0c-10.32,0 -19.23,-3.7 -26.48,-10.96 -7.27,-7.25 -10.84,-16.16 -10.84,-26.6l0 -504c0,-10.31 -4.7,-16.16 -14.12,-17.19 -9.43,-1.02 -17.31,2.67 -23.55,10.95l-263.55 424.14c-5.23,8.4 -13.63,12.6 -25.09,12.6 -11.56,0 -20.52,-4.2 -26.76,-12.6l-263.51 -424.14c-5.22,-8.28 -12.6,-11.97 -22.02,-10.95 -9.3,1.03 -14,6.88 -14,17.19l0 504c0,10.44 -3.73,19.35 -10.99,26.6 -7.27,7.26 -16.01,10.96 -26.49,10.96l-235.1 0c-10.44,0 -19.24,-3.7 -26.49,-10.96 -7.26,-7.25 -10.83,-16.16 -10.83,-26.6l0 -1069.02c0,-10.31 3.57,-19.22 10.83,-26.59 7.25,-7.27 16.16,-10.96 26.49,-10.96l218.03 0c10.32,0 21.31,3.18 32.77,9.42 11.3,6.24 19.73,13.62 24.84,21.89l301.19 441.33c6.27,8.4 13.65,12.6 22.04,12.6 8.29,0 15.15,-4.2 20.37,-12.6l301.24 -441.33c6.23,-8.27 15.03,-15.65 26.48,-21.89 11.34,-6.24 22.3,-9.42 32.73,-9.42l217.95 0c10.45,0 19.23,3.69 26.49,10.96 7.26,7.37 10.95,16.28 10.95,26.59z"/>
        <path className="fil0" d="M2578.28 4011.28l0 65.73c0,10.44 3.68,19.35 10.95,26.61 7.26,7.26 16.17,10.95 26.6,10.95l424.18 0c10.44,0 19.22,3.68 26.6,10.95 7.26,7.42 10.96,16.21 10.96,26.64l0 215.99c0,10.44 -3.7,19.24 -10.96,26.65 -7.38,7.26 -16.16,10.95 -26.6,10.95l-424.18 0c-10.43,0 -19.34,3.57 -26.6,10.95 -7.27,7.26 -10.95,16.16 -10.95,26.61l0 59.49c0,10.43 3.68,19.22 10.95,26.48 7.26,7.38 16.17,10.95 26.6,10.95l486.81 0c10.32,0 19.23,3.69 26.48,10.96 7.42,7.4 11,16.31 11,26.64l0 215.99c0,10.43 -3.58,19.39 -11,26.64 -7.25,7.27 -16.16,10.95 -26.48,10.95l-796.66 0c-10.45,0 -19.39,-3.68 -26.65,-10.95 -7.26,-7.25 -10.95,-16.21 -10.95,-26.64l0 -1070.5c0,-10.48 3.69,-19.27 10.95,-26.65 7.26,-7.25 16.2,-10.95 26.65,-10.95l796.66 0c10.32,0 19.23,3.7 26.48,10.95 7.42,7.38 11,16.17 11,26.65l0 212.81c0,10.44 -3.58,19.35 -11,26.61 -7.25,7.41 -16.16,10.98 -26.48,10.98l-486.81 0c-10.43,0 -19.34,3.7 -26.6,10.96 -7.27,7.37 -10.95,16.16 -10.95,26.6z"/>
        <path className="fil0" d="M3687.78 4214.81l165.05 0c12.49,0 25.47,-2.42 39,-7.14 13.51,-4.71 25.99,-11.97 37.33,-21.89 11.45,-9.94 20.76,-22.18 28.02,-36.81 7.26,-14.53 10.94,-31.72 10.94,-51.57 0,-10.48 -1.8,-22.68 -5.48,-36.81 -3.57,-14.05 -9.81,-27.67 -18.61,-40.66 -8.9,-13.1 -20.76,-24.05 -35.91,-32.84 -15.03,-8.91 -33.5,-13.38 -55.29,-13.38l-165.05 0c-10.32,0 -19.24,3.68 -26.49,10.95 -7.26,7.37 -10.83,16.16 -10.83,26.61l0 165.99c0,10.44 3.57,19.23 10.83,26.6 7.25,7.27 16.17,10.95 26.49,10.95zm-75.15 616.59l-234.36 0c-10.44,0 -19.35,-3.7 -26.77,-10.96 -7.37,-7.25 -10.94,-16.16 -10.94,-26.6l0 -1070.54c0,-10.44 3.57,-19.23 10.94,-26.61 7.27,-7.26 16.21,-10.94 26.66,-10.94l486.64 0c60.52,0 116.32,10.15 167.49,30.56 51.1,20.37 94.65,48.78 130.71,85.2 36.03,36.53 64.16,79.86 84.42,129.9 20.4,50.19 30.57,105.47 30.57,165.99 0,33.36 -3.93,66.47 -11.7,99.32 -7.77,32.85 -19.11,63.93 -33.63,93.12 -14.64,29.28 -31.86,55.65 -51.71,79.08 -19.75,23.43 -42.28,43.05 -67.27,58.7 -8.4,6.24 -13.85,13.78 -16.41,22.68 -2.67,8.91 -0.78,17.47 5.46,25.87 39.64,52.11 81.39,105.84 125.22,161.13 43.8,55.42 82.92,105.95 117.3,151.83 6.36,8.39 7.88,17.58 4.71,27.35 -3.06,9.97 -9.93,14.92 -20.25,14.92l-291.14 0c-10.48,0 -21.67,-2.8 -33.64,-8.68 -11.96,-5.73 -21.14,-12.71 -27.39,-20.99 -33.23,-43.95 -66.24,-88.26 -98.96,-133.12 -32.73,-44.81 -65.22,-90.17 -97.33,-136.12 -6.24,-8.29 -15.03,-15.43 -26.48,-21.16 -11.46,-5.73 -22.3,-8.56 -32.73,-8.56l-34.26 0c-10.32,0 -19.24,3.58 -26.49,11 -7.26,7.25 -10.83,16.16 -10.83,26.48l0 253.59c0,10.44 -3.7,19.35 -11.07,26.6 -7.42,7.26 -16.32,10.96 -26.76,10.96z"/>
        <path className="fil0" d="M5572.31 4527.76c-30.29,48.89 -63.69,93.35 -100.22,133.34 -36.46,39.87 -77.47,73.9 -122.79,101.92 -45.36,28.02 -95.8,49.8 -151.05,65.34 -55.29,15.54 -116.31,23.43 -183.18,23.43 -83.43,0 -161.61,-15.67 -234.71,-47.02 -73,-31.32 -136.96,-73.47 -191.7,-126.72 -54.79,-53.25 -97.84,-115.92 -129.15,-187.85 -31.36,-71.97 -46.9,-148.64 -46.9,-230.04 0,-81.39 15.54,-158.07 46.9,-230.04 31.31,-72.08 74.36,-134.6 129.15,-187.85 54.74,-53.26 118.7,-95.52 191.7,-126.76 73.1,-31.31 151.28,-46.97 234.71,-46.97 68.9,0 130.95,7.77 186.36,23.42 55.25,15.67 105.33,37.6 150.14,65.74 44.86,28.25 85.35,62.39 121.38,102.54 35.94,40.22 69.07,84.29 99.36,132.33 5.22,8.27 6.24,17.18 3.06,26.6 -3.06,9.3 -9.3,16.17 -18.72,20.25l-222.27 87.63c-10.43,4.2 -20.88,4.2 -31.31,0 -10.44,-4.08 -18.21,-10.32 -23.43,-18.72 -30.33,-48.03 -66.75,-84.84 -109.57,-110.31 -42.77,-25.59 -94.5,-38.34 -155,-38.34 -39.6,0 -76.92,8.28 -111.81,25.12 -35.04,16.68 -65.49,38.97 -91.59,66.59 -26.1,27.79 -46.5,60 -61.03,96.53 -14.62,36.7 -21.89,74.3 -21.89,112.99 0,39.75 7.27,77.7 21.89,113.76 14.53,36.14 34.93,67.74 61.03,95.01 26.1,27.11 56.55,49.17 91.59,65.84 34.89,16.8 72.21,25.09 111.81,25.09 57.44,0 109.28,-13.74 155.79,-41.37 46.34,-27.67 83.15,-63.45 110.27,-107.25 5.26,-8.44 12.87,-14.67 22.81,-18.87 9.81,-4.09 20.01,-4.09 30.44,0l222.27 87.63c9.42,4.19 15.66,10.82 18.72,19.61 3.18,8.91 2.16,17.98 -3.06,27.4z"/>
        <path className="fil0" d="M6071.6 4402.55l139.35 0c10.32,0 17.93,-3.29 22.64,-10.2 4.74,-6.75 4.48,-14.87 -0.74,-24.17 -10.45,-26.13 -23.04,-56.7 -37.56,-91.6 -14.68,-34.88 -28.17,-67.49 -40.78,-97.82 -4.19,-9.42 -8.78,-14.13 -14,-14.13 -5.22,0 -9.93,4.71 -14.13,14.13l-78.21 189.42c-4.21,9.3 -4.21,17.42 0,24.17 4.08,6.91 11.97,10.2 23.43,10.2zm-92.34 -680.77c4.2,-9.43 11.22,-17.47 21.15,-24.34 9.93,-6.74 20.61,-10.2 32.1,-10.2l219.05 0c10.44,0 20.64,3.46 30.57,10.2 9.81,6.87 16.95,14.91 21.16,24.34l456.98 1064.29c4.08,9.31 3.33,19.23 -2.4,29.66 -5.77,10.45 -16.95,15.67 -33.63,15.67l-267.59 0c-11.5,0 -22.18,-3.3 -32.1,-10.2 -9.94,-6.76 -17.47,-14.88 -22.68,-24.18l-42.31 -94.03c-5.22,-9.25 -12.71,-17.42 -22.65,-24.17 -9.92,-6.75 -20.12,-10.2 -30.57,-10.2l-330.26 0c-11.46,0 -22.17,3.45 -31.99,10.2 -9.92,6.75 -17.06,14.92 -21.14,24.17l-43.8 94.03c-4.2,9.3 -11.34,17.42 -21.15,24.18 -9.93,6.9 -20.13,10.2 -30.57,10.2l-269.12 0c-5.23,0 -10.72,-1.03 -16.46,-3.18 -5.72,-2.04 -10.43,-5.1 -14.12,-9.3 -3.69,-4.2 -5.97,-9.41 -6.98,-15.66 -1.02,-6.24 0.5,-13.62 4.55,-21.9l453.96 -1059.58z"/>
        <path className="fil0" d="M7220.33 4543.42l117.41 0c29.2,0 59.49,-3.68 90.85,-10.94 31.19,-7.27 59.45,-21.16 84.41,-41.52 25.12,-20.26 45.6,-49.06 61.92,-85.99 16.06,-37.08 24.21,-85.35 24.21,-144.8 0,-59.49 -8.15,-107.76 -24.21,-144.84 -16.32,-36.93 -37.08,-65.7 -62.67,-86.1 -25.58,-20.26 -53.76,-34.14 -84.56,-41.4 -30.69,-7.22 -60.75,-10.95 -89.95,-10.95l-117.41 0c-10.45,0 -19.23,3.73 -26.61,10.95 -7.26,7.26 -10.95,16.2 -10.95,26.64l0 491.4c0,10.43 3.69,19.34 10.95,26.61 7.38,7.26 16.16,10.94 26.61,10.94zm-347.46 250.41l0 -1070.54c0,-10.43 3.69,-19.22 10.95,-26.61 7.37,-7.25 16.17,-10.94 26.61,-10.94l427.31 0c163.8,0 297.93,48 402.24,144.06 56.31,52.07 99.08,114.23 128.4,186.2 29.16,71.98 43.8,152.86 43.8,242.64 0,89.67 -14.64,170.78 -43.8,243.27 -29.32,72.59 -72.09,134.87 -128.4,187.1 -52.19,46.85 -111.96,82.42 -179.18,106.35 -67.27,24.06 -141.67,36.03 -223.06,36.03l-427.31 0c-10.44,0 -19.24,-3.7 -26.61,-10.95 -7.26,-7.26 -10.95,-16.17 -10.95,-26.61z"/>
        <path className="fil0" d="M8049.87 4258.63c0,-81.4 15.54,-158.07 46.85,-230.16 31.36,-71.97 73.62,-134.76 126.88,-188.48 53.13,-53.76 115.77,-96.3 187.74,-127.66 71.96,-31.31 148.65,-46.97 230.03,-46.97 81.4,0 158.2,15.66 230.17,46.97 71.92,31.36 134.75,73.9 188.48,127.66 53.76,53.72 96.3,116.51 127.61,188.48 31.36,72.09 47.01,148.76 47.01,230.16 0,81.39 -15.65,158.07 -47.01,230.04 -31.31,71.93 -73.85,134.88 -127.61,188.59 -53.73,53.77 -116.56,96.19 -188.48,127.51 -71.97,31.35 -148.77,47.01 -230.17,47.01 -81.38,0 -158.07,-15.66 -230.03,-47.01 -71.97,-31.32 -134.61,-73.74 -187.74,-127.51 -53.26,-53.71 -95.52,-116.66 -126.88,-188.59 -31.31,-71.97 -46.85,-148.65 -46.85,-230.04z"/>
        <path className="fil2" d="M9395.77 3724.82c0,-10.31 3.69,-19.22 10.95,-26.59 7.26,-7.27 16.05,-10.96 26.49,-10.96l235.14 0c10.32,0 19.23,3.69 26.49,10.96 7.25,7.37 10.83,16.28 10.83,26.59l0 633.93c0,40.74 6.59,74.37 19.74,100.98 12.99,26.48 29.28,47.41 48.65,62.54 19.36,15.16 40.12,25.87 62.01,32.11 22.06,6.24 42.04,9.42 59.73,9.42 17.86,0 37.71,-3.18 59.76,-9.42 22.02,-6.24 42.38,-16.95 61.26,-32.11 18.85,-15.13 34.89,-36.06 48,-62.54 13.15,-26.61 19.62,-60.24 19.62,-100.98l0 -633.93c0,-10.31 3.57,-19.22 10.83,-26.59 7.26,-7.27 16.17,-10.96 26.49,-10.96l235.26 0c10.32,0 19.11,3.69 26.37,10.96 7.26,7.37 10.94,16.28 10.94,26.59l0 633.93c0,74.13 -11.72,141.35 -35.15,201.87 -23.43,60.51 -57.06,112.19 -100.74,155.01 -43.84,42.77 -96.42,75.89 -157.83,99.36 -61.5,23.43 -129.78,35.12 -204.81,35.12 -74.99,0 -143.16,-11.69 -204.65,-35.12 -61.53,-23.47 -114.12,-56.59 -157.83,-99.36 -43.79,-42.82 -77.54,-94.5 -101.64,-155.01 -23.94,-60.52 -35.91,-127.74 -35.91,-201.87l0 -633.93z"/>
        <path className="fil2" d="M11705.84 3723.3l0 1070.54c0,10.44 -3.57,19.35 -10.83,26.61 -7.38,7.25 -16.17,10.95 -26.61,10.95l-231.18 0c-10.43,0 -21.27,-3.18 -32.73,-9.42 -11.45,-6.25 -20.4,-13.5 -26.64,-21.9l-407.58 -574.31c-6.24,-8.4 -12.82,-11.86 -19.58,-10.2 -6.75,1.52 -10.08,7.53 -10.08,17.96l0 560.31c0,10.44 -3.69,19.35 -11.07,26.61 -7.38,7.25 -16.32,10.95 -26.77,10.95l-234.34 0c-10.45,0 -19.36,-3.7 -26.73,-10.95 -7.42,-7.26 -11,-16.17 -11,-26.61l0 -1070.54c0,-10.43 3.58,-19.22 11,-26.61 7.26,-7.25 16.17,-10.94 26.6,-10.94l234.75 0c10.32,0 21.39,2.9 32.85,8.63 11.46,5.73 20.25,12.76 26.61,21.16l406.94 574.31c6.24,8.4 12.75,12.08 19.5,10.95 6.76,-1.03 10.17,-6.75 10.17,-17.19l0 -560.31c0,-10.43 3.73,-19.22 10.99,-26.61 7.25,-7.25 16.16,-10.94 26.6,-10.94l231.69 0c10.32,0 19.23,3.69 26.49,10.94 7.38,7.39 10.95,16.18 10.95,26.61z"/>
        <path className="fil2" d="M12225.63 4831.4l-235.11 0c-11.49,0 -20.52,-3.69 -27.27,-10.95 -6.74,-7.25 -10.05,-16.16 -10.05,-26.61l0 -1069c0,-10.33 3.31,-19.23 10.05,-26.65 6.75,-7.26 15.78,-10.94 27.27,-10.94l235.11 0c10.43,0 19.23,3.68 26.48,10.94 7.27,7.42 10.96,16.32 10.96,26.65l0 1069c0,10.45 -3.69,19.36 -10.96,26.61 -7.25,7.26 -16.05,10.95 -26.48,10.95z"/>
        <path className="fil2" d="M12823.36 4543.42l117.3 0c29.31,0 59.49,-3.68 90.8,-10.94 31.36,-7.27 59.5,-21.16 84.58,-41.52 25,-20.26 45.6,-49.06 61.8,-85.99 16.17,-37.08 24.33,-85.35 24.33,-144.8 0,-59.49 -8.16,-107.76 -24.33,-144.84 -16.2,-36.93 -37.08,-65.7 -62.55,-86.1 -25.59,-20.26 -53.76,-34.14 -84.57,-41.4 -30.85,-7.22 -60.75,-10.95 -90.06,-10.95l-117.3 0c-10.44,0 -19.35,3.73 -26.6,10.95 -7.26,7.26 -10.96,16.2 -10.96,26.64l0 491.4c0,10.43 3.7,19.34 10.96,26.61 7.25,7.26 16.16,10.94 26.6,10.94zm-347.44 250.41l0 -1070.54c0,-10.43 3.57,-19.22 10.94,-26.61 7.25,-7.25 16.16,-10.94 26.61,-10.94l427.19 0c163.8,0 297.93,48 402.24,144.06 56.43,52.07 99.2,114.23 128.4,186.2 29.16,71.98 43.8,152.86 43.8,242.64 0,89.67 -14.64,170.78 -43.8,243.27 -29.2,72.59 -71.97,134.87 -128.4,187.1 -52.08,46.85 -111.84,82.42 -179.23,106.35 -67.22,24.06 -141.62,36.03 -223.01,36.03l-427.19 0c-10.45,0 -19.36,-3.7 -26.61,-10.95 -7.37,-7.26 -10.94,-16.17 -10.94,-26.61z"/>
        <path className="fil2" d="M13962.8 4258.63c0,39.6 7.26,77.19 21.89,112.58 14.52,35.55 34.66,66.88 60.24,93.99 25.51,27.15 55.57,48.82 89.95,64.98 34.49,16.18 70.95,24.18 109.52,24.18 38.61,0 75.16,-8 109.69,-24.18 34.37,-16.16 64.55,-37.83 90.69,-64.98 26.1,-27.11 46.46,-58.44 61.1,-93.99 14.52,-35.39 21.94,-72.98 21.94,-112.58 0,-38.58 -7.42,-75.9 -21.94,-111.96 -14.64,-36.02 -35,-67.26 -61.1,-93.87 -26.14,-26.61 -56.32,-48.03 -90.69,-64.21 -34.53,-16.16 -71.08,-24.32 -109.69,-24.32 -38.57,0 -75.03,8.16 -109.52,24.32 -34.38,16.18 -64.44,37.6 -89.95,64.21 -25.58,26.61 -45.72,57.85 -60.24,93.87 -14.63,36.06 -21.89,73.38 -21.89,111.96zm-309.9 0c0,-81.4 15.54,-158.07 46.89,-230.16 31.32,-71.97 73.63,-134.76 126.83,-188.48 53.14,-53.76 115.81,-96.3 187.79,-127.66 71.92,-31.31 148.6,-46.97 229.99,-46.97 81.4,0 158.19,15.66 230.16,46.97 71.97,31.36 134.76,73.9 188.53,127.66 53.71,53.72 96.25,116.51 127.61,188.48 31.31,72.09 46.97,148.76 46.97,230.16 0,81.39 -15.66,158.07 -46.97,230.04 -31.36,71.93 -73.9,134.88 -127.61,188.59 -53.77,53.77 -116.56,96.19 -188.53,127.51 -71.97,31.35 -148.76,47.01 -230.16,47.01 -81.39,0 -158.07,-15.66 -229.99,-47.01 -71.98,-31.32 -134.65,-73.74 -187.79,-127.51 -53.2,-53.71 -95.51,-116.66 -126.83,-188.59 -31.35,-71.97 -46.89,-148.65 -46.89,-230.04z"/>
        <path className="fil2" d="M8359.76 4258.63c0,39.6 7.26,77.19 21.89,112.58 14.53,35.55 34.66,66.88 60.25,93.99 25.51,27.15 55.56,48.82 89.94,64.98 34.49,16.18 70.96,24.18 109.52,24.18 38.62,0 75.16,-8 109.68,-24.18 34.39,-16.16 64.57,-37.83 90.7,-64.98 26.09,-27.11 46.46,-58.44 61.1,-93.99 14.52,-35.39 21.94,-72.98 21.94,-112.58 0,-38.58 -7.42,-75.9 -21.94,-111.96 -14.64,-36.02 -35.01,-67.26 -61.1,-93.87 -26.13,-26.61 -56.31,-48.03 -90.7,-64.21 -34.52,-16.16 -71.06,-24.32 -109.68,-24.32 -38.56,0 -75.03,8.16 -109.52,24.32 -34.38,16.18 -64.43,37.6 -89.94,64.21 -25.59,26.61 -45.72,57.85 -60.25,93.87 -14.63,36.06 -21.89,73.38 -21.89,111.96z"/>
        <rect className="fil3" transform="matrix(-1.76793 1.61803 -1.76793 -1.61803 14527.4 5442.95)" width="462.78" height="462.78" rx="60" ry="60"/>
        </g>
      </g>
    </svg>
  )
}

export default WhiteLogo;