import { Link } from "react-router-dom";
import RedLogo from '../assets/logo/RedLogo';
import instaLogo from '../assets/icons/MERCADOUNIDOINSTA.png'
import faceLogo from '../assets/icons/MERCADOUNIDOFACE.png'
import linkedinLogo from '../assets/icons/MERCADOUNIDOIN.png'

const date = new Date();
const year = date.getFullYear();

const navigation = {
  main: [
    { name: 'Central de Ajuda', href: 'helpcenter' },
    { name: 'Termos de Uso', href: 'termsofuse' },
    { name: 'Política de Privacidade', href: 'privacypolicies' },
    { name: 'Venda no Mercado Unido', href: 'sellhere' },
  ],
  social: [
    {
      name: 'Facebook',
      href: '#',
      icon: faceLogo,
    },
    {
      name: 'Instagram',
      href: '#',
      icon: instaLogo
    },
    {
      name: 'Linkedin',
      href: '#',
      icon: linkedinLogo
    },
  ],
}

export default function Footer() {
  return (
    <>
      {/* MOBILE */}
      <footer className="lg:sticky lg:bottom-0 bg-primary-gray w-full pt-14 pb-6 flex flex-col items-center justify-center lg:hidden">
          <RedLogo />
          {/* <img className="mx-auto w-52" src={redLogo} alt="Mercado Unido" /> */}
          <nav className="flex flex-col items-center py-6">
            {navigation.main.map((el, index) => (
              <Link to={`/${el.href}`} className="py-1 text-md font-bold text-primary-red" key={index} rel="noreferrer">{el.name}</Link>
            ))}
          </nav>
          <nav className="flex flex-row items-center">
            {navigation.social.map((el, index) => (
              <a className="text-primary-red px-1 w-10" key={index} target="_blank" href={el.href} rel="noreferrer">
                <img src={el.icon} alt={el.name} />
              </a>
            ))}
          </nav>
          <div className=" pt-16 text-center text-xs text-gray-500">
            <p>
              CNPJ: 49.114.588/0001-01 / Inscrição Estadual: <br />
              85.687.08-5 / Endereço Rua Francisco Rocha, 198 - <br/>
              Pe 251 14 - Batel - CEP: 80420-130 - Curitiba/PR
            </p>
            <span>© {year} Mercado Unido. Todos os direitos reservados</span>
          </div>
      </footer>

      {/* DESKTOP */}
      <footer className="hidden lg:flex bg-primary-gray w-full pt-14 pb-2 flex-col items-center">
        <section className="max-w-[1920px] w-full">
          <div className="flex flex-row items-center justify-evenly w-full">
            <RedLogo />
            <nav className="items-center">
              {navigation.main.map((el, index) => (
                <Link to={`/${el.href}`} className="px-4 text-md font-bold text-primary-red hover:opacity-70 duration-150" key={index}  rel="noreferrer">
                  <span>{el.name}</span>
                </Link>
              ))}
            </nav>
            <nav className="flex flex-row items-center">
              {navigation.social.map((el, index) => (
                <a className="text-primary-red px-1 w-10 hover:scale-110 duration-150" key={index} target="_blank" href={el.href} rel="noreferrer">
                  <img src={el.icon} alt={el.name} />
                </a>
              ))}
            </nav>
          </div>
          <div className="pt-10 text-center text-xs text-gray-500">
            <p className="">
              CNPJ: 49.114.588/0001-01 / Inscrição Estadual: 85.687.08-5 / Endereço Rua Francisco Rocha, 198 - Pe 251 14 - Batel - CEP: 80420-130 - Curitiba/PR
            </p>
            <span className="">© {year} Mercado Unido. Todos os direitos reservados</span>
          </div>
        </section>
      </footer>
    </>
  )
}