import React, { useContext } from 'react';
import AuthContext from '../../context/AuthContext';
import { IMaskInput } from 'react-imask';

export default function EnderecoEntregaFields() {
    const { user } = useContext(AuthContext);

    return (
        <>
            <div className="sm:col-span-6 text-left">
                <label className="block text-sm font-bold text-[16px] text-primary-blue">
                    CEP
                </label>
                <div className="mt-1 flex rounded-md shadow-sm">
                    <div className="relative flex flex-grow items-stretch focus-within:z-10">
                        <IMaskInput
                            value={user.mainAddress.cep}
                            mask='00.000-000'
                            type="text"
                            className="block w-full rounded-l-[11px] border-gray-300 shadow-sm sm:text-sm py-1 px-2 focus:border-primary-blue focus:outline-none focus:ring-1 focus:ring-primary-blue pr-[11px] pl-[11px] pb-[11px] pt-[11px]"
                        />
                    </div>
                    <button
                        type="button"
                        className="relative -ml-px inline-flex items-center space-x-2 rounded-r-md border border-primary-blue bg-primary-blue px-4 py-2 text-sm font-medium text-white hover:opacity-80"
                    >
                        OK
                    </button>
                </div>
            </div>

            <div className="sm:col-span-3">
                <label className="block text-sm font-bold text-[16px] text-primary-blue text-left">
                    Endereço
                </label>
                <div className="mt-1">
                    <input className="block w-full rounded-[11px] border-gray-300 shadow-sm sm:text-sm py-1 px-2 focus:border-primary-blue focus:outline-none focus:ring-1 focus:ring-primary-blue pr-[11px] pl-[11px] pb-[11px] pt-[11px]"
                        type="text"
                        value={user.mainAddress.street}
                        maxLength="255"
                    />
                </div>
            </div>

            <div className="sm:col-span-1">
                <label className="block text-sm font-bold text-[16px] text-primary-blue text-left">
                    Número
                </label>
                <div className="mt-1">
                    <input className="block w-full rounded-[11px] border-gray-300 shadow-sm sm:text-sm py-1 px-2 focus:border-primary-blue focus:outline-none focus:ring-1 focus:ring-primary-blue pr-[11px] pl-[11px] pb-[11px] pt-[11px]"
                        type="text"
                        value={user.mainAddress.number}
                        maxLength="255"
                    />
                </div>
            </div>

            <div className="sm:col-span-2">
                <label className="block text-sm font-bold text-[16px] text-primary-blue text-left">
                    Bairro
                </label>
                <div className="mt-1">
                    <input className="block w-full rounded-[11px] border-gray-300 shadow-sm sm:text-sm py-1 px-2 focus:border-primary-blue focus:outline-none focus:ring-1 focus:ring-primary-blue pr-[11px] pl-[11px] pb-[11px] pt-[11px]"
                        type="text"
                        value={user.mainAddress.district}
                        maxLength="255"
                    />
                </div>
            </div>

            <div className="sm:col-span-2">
                <label className="block text-sm font-bold text-[16px] text-primary-blue text-left">
                    Cidade
                </label>
                <div className="mt-1">
                    <input className="block w-full rounded-[11px] border-gray-300 shadow-sm sm:text-sm py-1 px-2 focus:border-primary-blue focus:outline-none focus:ring-1 focus:ring-primary-blue pr-[11px] pl-[11px] pb-[11px] pt-[11px]"
                        type="text"
                        value={user.mainAddress.city}
                        maxLength="255"
                    />
                </div>
            </div>

            <div className="sm:col-span-1">
                <label className="block text-sm font-bold text-[16px] text-primary-blue text-left">
                    UF
                </label>
                <div className="mt-1">
                    <input className="block w-full rounded-[11px] border-gray-300 shadow-sm sm:text-sm py-1 px-2 focus:border-primary-blue focus:outline-none focus:ring-1 focus:ring-primary-blue pr-[11px] pl-[11px] pb-[11px] pt-[11px]"
                        type="text"
                        value={user.mainAddress.state}
                        maxLength="255"
                    />
                </div>
            </div>

            <div className="sm:col-span-6">
                <label className="block text-sm font-bold text-[16px] text-primary-blue text-left">
                    Complemento
                </label>
                <div className="mt-1">
                    <input className="block w-full rounded-[11px] border-gray-300 shadow-sm sm:text-sm py-1 px-2 focus:border-primary-blue focus:outline-none focus:ring-1 focus:ring-primary-blue pr-[11px] pl-[11px] pb-[11px] pt-[11px]"
                        type="text"
                        value={user.mainAddress.complement}
                        maxLength="255"
                    />
                </div>
            </div>

            <div className="sm:col-span-6">
                <label className="block text-sm font-bold text-[16px] text-primary-blue text-left">
                    Ponto de Referência
                </label>
                <div className="mt-1">
                    <input className="block w-full rounded-[11px] border-gray-300 shadow-sm sm:text-sm py-1 px-2 focus:border-primary-blue focus:outline-none focus:ring-1 focus:ring-primary-blue pr-[11px] pl-[11px] pb-[11px] pt-[11px]"
                        type="text"
                        value={user.mainAddress.reference}
                        maxLength="255"
                    />
                </div>
            </div>
        </>
    )
}