import React from "react";

export default function CompanyCard(
  // {name, logo, price}
) {
  return (
    <div className="bg-white py-1 rounded-xl inline-block mx-2 text-center w-[142px] h-[89px]">
      <img className="w-[80px] mx-auto pt-[13px] px-[15px]" src="https://logodownload.org/wp-content/uploads/2014/05/casas-bahia-logo-6.png" alt="Lojas Americanas" />
      <p className="font-bold text-dark-gray mt-[4px] -mb-2">R$ 11.000,00</p>
      <a className="text-orange-500 underline decoration-solid text-[13px]" target='_blank' href="https://www.americanas.com.br/produto/3390167278?opn=YSMESP&cor=Black" rel="noreferrer">Conferir</a>
    </div>
  )
}