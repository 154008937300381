import React from 'react';
import Footer from '../components/Footer';
import Header from '../components/Header';
import { AiOutlineArrowLeft } from "react-icons/ai";
import { useNavigate } from 'react-router-dom';


function TermsOfUse() {
  const navigate = useNavigate();

  return (
    <>
      <Header />
      <section className="bg-background-gray py-10">
        <article className="bg-white mx-auto rounded-md w-5/6 shadow-md px-8 py-12 lg:pb-[60px] lg:pt-[38px] lg:px-[90px] max-w-screen-lg">
          <div className='flex flex-row items-center hover:cursor-pointer hover:opacity-80' onClick={() => navigate(-1)}>
            <AiOutlineArrowLeft className='text-primary-blue font-bold' />
            <p className='px-2 text-primary-blue underline'>Voltar</p>
          </div>
          <h3 className="text-primary-blue text-left w-full font-bold text-[35px]">Termos de Uso</h3>
          <p className="pt-[30px] text-secondary-dark-gray">Bem-vindo ao Mercado Unido, uma Rede Social de Compra Coletiva, onde você pode comprar produtos em grupo para obter descontos exclusivos. Este documento contém os termos e condições que se aplicam ao uso do nosso site e à compra de produtos em nosso marketplace. Leia atentamente antes de utilizar nossos serviços. </p>
          <p className="pt-[20px] text-secondary-dark-gray">Informações sobre o Mercado Unido<br></br>O Mercado Unido é uma plataforma online que conecta compradores e atacadistas para comprar produtos em grupo e obter descontos exclusivos. Nós somos responsáveis por organizar as compras coletivas e garantir que os produtos sejam entregues corretamente.</p>
          <p className="pt-[20px] text-secondary-dark-gray">
            Descrição dos produtos ou serviços oferecidos em nosso marketplace <br></br>
            Oferecemos diversos produtos em nosso marketplace, incluindo produtos eletrônicos, vestuário, acessórios, alimentos, bebidas, entre outros.
          </p>
          <p className="pt-[20px] text-secondary-dark-gray">Preços e informações sobre o pagamento<br></br>Os preços dos produtos variam de acordo com o produto e a quantidade mínima exigida pelo atacadista. Aceitamos apenas pagamentos com cartão de crédito. As taxas aplicáveis serão informadas no momento da compra. </p>
          <p className="pt-[20px] text-secondary-dark-gray">Política de envio e entrega dos produtos<br></br>Os produtos serão entregues por uma empresa terceirizada e o comprador pode escolher entre as opções disponíveis para a sua região. O prazo de entrega pode variar de acordo com o local de entrega e a disponibilidade do produto.</p>
          <p className="pt-[20px] text-secondary-dark-gray">Política de devolução e reembolso dos produtos<br></br>O reembolso seguirá o Código de Defesa do Consumidor. O comprador tem o direito de desistir da compra em até 7 dias após o recebimento do produto, desde que o produto não tenha sido usado. Além disso, se a quantidade mínima de compra não for atingida, o dinheiro será estornado no cartão de crédito do comprador.</p>
          <p className="pt-[20px] text-secondary-dark-gray">Propriedade intelectual<br></br>A marca Mercado Unido é registrada no INPI e é de propriedade exclusiva da nossa empresa. Os produtos e serviços oferecidos em nosso marketplace são de propriedade dos atacadistas e fornecedores que os disponibilizam em nossa plataforma. </p>
          <p className="pt-[20px] text-secondary-dark-gray">Responsabilidades dos vendedores e compradores<br></br>O Mercado Unido é responsável por organizar as compras coletivas e garantir a entrega dos produtos, desde que a quantidade mínima exigida pelo atacadista seja atingida. O comprador é responsável por inserir as informações corretas, especialmente as informações de entrega. </p>
          <p className="pt-[20px] text-secondary-dark-gray">Política de privacidade<br></br>Coletamos informações dos usuários ao se cadastrar no site, incluindo dados pessoais como nome, CPF, endereço de e-mail e informações de pagamento. Essas informações são usadas para melhorar a experiência do usuário e para fins de marketing. As informações são protegidas contra acesso não autorizado, roubo e uso indevido, através de medidas de segurança administrativas, físicas e técnicas. </p>
          <p className="pt-[20px] text-secondary-dark-gray">Ao utilizar nosso site e realizar compras em nosso marketplace, você concorda com nossos termos e condições. Se tiver alguma dúvida ou preocupação, entre em contato conosco através do e-mail contato@mercadounido.com.</p>
        </article>
      </section>
      <Footer />
    </>

  )
}

export default TermsOfUse;