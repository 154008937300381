import React, { useState } from 'react';
import AddressContext from './AddressContext';
import * as address from './../services/address.js';

export const AddressProvider = ({ children }) => {

    const [idAddress, setIdAddress] = useState('');
    const [zipCode, setZipCode] = useState('');
    const [street, setStreet] = useState('');
    const [number, setNumber] = useState('');
    const [district, setDistrict] = useState('');
    const [city, setCity] = useState('');
    const [state, setState] = useState('');
    const [complement, setComplement] = useState('');
    const [reference, setReference] = useState('');
    const [isMainChecked, setIsMainChecked] = useState(false);

    const [isUpdate, setIsUpdate] = useState(false);

    async function updateAddress(parameters, token) {
        const response = await address.update(parameters, token);

        return (response.status === 200) ? response : false;
    }

    async function deleteAddress(idAddress, token) {
        const response = await address.deletar(idAddress, token);

        return (response.status === 200) ? response : false;
    }

    async function setMainAddress(idAddress, token) {
        const response = await address.setMain(idAddress, token);

        return (response.status === 200) ? response : false;
    }


    const context = {
        idAddress, setIdAddress,
        zipCode, setZipCode,
        street, setStreet,
        number, setNumber,
        district, setDistrict,
        city, setCity,
        state, setState,
        complement, setComplement,
        reference, setReference,
        isMainChecked, setIsMainChecked,
        isUpdate, setIsUpdate,
        updateAddress, deleteAddress, setMainAddress
    };

    return (
        <AddressContext.Provider value={context}>
            {children}
        </AddressContext.Provider>
    );
}