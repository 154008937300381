import axios from 'axios';
import { url } from './api';

export const create = async (parameters) => {
    try {
        const response = await axios.post(url + `/user/adresses`, {
            street: parameters.street,
            district: parameters.district,
            number: parameters.number,
            cep: parameters.cep,
            city: parameters.city,
            state: parameters.state,
            complement: (parameters.complement !== '' && parameters.complement !== null) ? parameters.complement : undefined,
            reference: (parameters.reference !== '' && parameters.reference !== null) ? parameters.reference : undefined,
            id_user: parameters.userId,
            main: (parameters.main === true) ? true : undefined,
        });

        return (response.status === 201) ? response : false;
    } catch (error) {
        console.log(error);
        return false;
    }
}

export const setMain = async (idAddress, token) => {
    try {
        const response = await axios.put(url + `/adresses/main/${idAddress}`, {
            headers: {
                'Authorization': `Bearer ${token}`,
            }
        })

        return (response.status === 200) ? response : false;
    } catch (error) {
        console.log(error);
        return false;
    }
}

export const deletar = async (idAddress, token) => {
    try {
        const response = await axios.delete(url + `/adresses/${idAddress}`, {
            headers: {
                'Authorization': `Bearer ${token}`,
            }
        })

        return (response.status === 200) ? response : false;
    } catch (error) {
        console.log(error);
        return false;
    }
}

export const update = async (parameters, token) => {
    try {
        const response = await axios.put(url + `/adresses/${parameters.idAddress}`, {
            street: (parameters.street !== '' && parameters.street !== null) ? parameters.street : undefined,
            district: (parameters.district !== '' && parameters.district !== null) ? parameters.district : undefined,
            number: (parameters.number !== '' && parameters.number !== null) ? parameters.number : undefined,
            cep: (parameters.cep !== '' && parameters.cep !== null) ? parameters.cep : undefined,
            city: (parameters.city !== '' && parameters.city !== null) ? parameters.city : undefined,
            state: (parameters.state !== '' && parameters.state !== null) ? parameters.state : undefined,
            complement: (parameters.complement !== '' && parameters.complement !== null) ? parameters.complement : undefined,
            reference: (parameters.reference !== '' && parameters.reference !== null) ? parameters.reference : undefined,
            main: (parameters.main !== '' && parameters.main !== null) ? parameters.main : undefined,
        }, {
            headers: {
                'Authorization': `Bearer ${token}`,
            }
        })

        return (response.status === 200) ? response : false;
    } catch (error) {
        console.log(error);
        return false;
    }
}