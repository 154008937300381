import React from 'react';
import WhiteLogo from '../assets/logo/WhiteLogo';
import { Link, useNavigate } from 'react-router-dom';
import { useContext } from 'react';
import PaymentContext from '../context/PaymentContext';


export default function QrCodePix() {
    const { qrCodePix } = useContext(PaymentContext);

    const date = new Date();
    const year = date.getFullYear();

    return (
        <div className='h-screen bg-background-gray'>
            <header className='w-full bg-primary-red h-[55px]'>
                <Link to="/">
                    <WhiteLogo className='h-full scale-125 hover:opacity-80 duration-150 mx-auto' />
                </Link>
            </header>
            <section className="bg-background-gray w-full h-full text-center pt-[20px]">
                { /* DESKTOP */}
                <article className='h-full hidden lg:grid grid-rows-2 grid-cols-2 gap-[10px] max-w-[1280px] mx-auto px-[20px]'>
                    <div className='row-span-1 col-span-2 bg-white rounded-xl shadow-md mx-auto w-full h-[600px] relative'>
                        <div className='rounded-t-xl bg-background-gray border-[1px] h-[50px] w-full flex items-center'>
                            <h2 className='text-primary-red text-left px-[39px] font-bold'>PAGAMENTO</h2>
                        </div>

                        <div className="flex flex-row justify-center justify-items-center mt-[80px]">
                            <div>
                                <img class="mx-auto mt-12 h-52 w-52 rounded-lg border p-2 md:mt-0" src={qrCodePix.charge.qr_codes[0].links[0].href} alt="step" />
                                <div>
                                    <h1 class="font-laonoto mt-4 text-center text-xl font-bold">ESCANEAR QR CODE</h1>
                                    <p class="mt-2 text-center font-semibold text-gray-600">ou</p>
                                </div>
                                <button className="mt-[10px] mx-auto block rounded-md border bg-blue-500 px-6 py-2 text-white outline-none">COPIAR CHAVE PIX</button>
                            </div>
                            <div class="mt-8 max-w-sm md:mt-0 md:ml-10 md:w-2/3">
                                <div class="relative flex pb-12">
                                    <div class="absolute inset-0 flex h-full w-10 items-center justify-center">
                                        <div class="pointer-events-none h-full w-1 bg-gray-200"></div>
                                    </div>
                                    <div class="relative z-10 inline-flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full bg-blue-500 text-white">
                                        <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="h-5 w-5" viewBox="0 0 24 24">
                                            <path d="M12 22s8-4 8-10V5l-8-3-8 3v7c0 6 8 10 8 10z"></path>
                                        </svg>
                                    </div>
                                    <div class="flex-grow pl-4">
                                        <h2 class="title-font mb-1 text-sm font-medium tracking-wider text-gray-900">PASSO 1</h2>
                                        <p class="font-laonoto leading-relaxed">
                                            Abra a câmera do seu celular ou o <br />
                                            aplicativo do banco e escaneie o <b>QR CODE </b>
                                        </p>
                                    </div>
                                </div>
                                <div class="relative flex pb-12">
                                    <div class="absolute inset-0 flex h-full w-10 items-center justify-center">
                                        <div class="pointer-events-none h-full w-1 bg-gray-200"></div>
                                    </div>
                                    <div class="relative z-10 inline-flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full bg-blue-500 text-white">
                                        <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="h-5 w-5" viewBox="0 0 24 24">
                                            <path d="M22 12h-4l-3 9L9 3l-3 9H2"></path>
                                        </svg>
                                    </div>
                                    <div class="flex-grow pl-4">
                                        <h2 class="title-font mb-1 text-sm font-medium tracking-wider text-gray-900">PASSO 2</h2>
                                        <p class="font-laonoto leading-relaxed">Verifique os dados do do pix escaneado e <b>confirme-o</b> pagamento.</p>
                                    </div>
                                </div>
                                <div class="relative flex pb-12">
                                    <div class="relative z-10 inline-flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full bg-blue-500 text-white">
                                        <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="h-5 w-5" viewBox="0 0 24 24">
                                            <circle cx="12" cy="5" r="3"></circle>
                                            <path d="M12 22V8M5 12H2a10 10 0 0020 0h-3"></path>
                                        </svg>
                                    </div>
                                    <div class="flex-grow pl-4">
                                        <h2 class="title-font mb-1 text-sm font-medium tracking-wider text-gray-900">PASSO 3</h2>
                                        <p class="font-laonoto leading-relaxed">
                                            Pronto, pagamento realizado com <span> <b>sucesso</b>!</span>.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </article>

                { /* MOBILE */ }
                <article className='grid grid-rows-2 gap-[12px] w-full mx-auto px-[20px] lg:hidden h-full'>
                    <div className='row-span-1 bg-white rounded-xl shadow-md mx-auto w-full'>
                        <div className='rounded-t-xl bg-background-gray border-[1px] h-[50px] w-full flex items-center'>
                            <h2 className='text-primary-red text-left px-[39px] font-bold'>PAGAMENTO</h2>
                        </div>
                        <aside className='text-left px-[20px] text-[14px] text-gray-700'>
                            <div className="flex justify-center justify-items-center ">
                                <div>
                                    <img class="mx-auto mt-12 h-52 w-52 rounded-lg border p-2 md:mt-0" src={qrCodePix.charge.qr_codes[0].links[0].href} alt="step" />
                                    <div>
                                        <h1 class="font-laonoto mt-4 text-center text-xl font-bold">ESCANEAR QR CODE</h1>
                                        <p class="mt-2 text-center font-semibold text-gray-600">ou</p>
                                    </div>
                                    <button className="mt-[10px] mx-auto block rounded-md border bg-blue-500 px-6 py-2 text-white outline-none">COPIAR CHAVE PIX</button>
                                </div>
                            </div>
                            <div class="mt-8 max-w-sm md:mt-0 md:ml-10 md:w-2/3">
                                <div class="relative flex pb-12">
                                    <div class="absolute inset-0 flex h-full w-10 items-center justify-center">
                                        <div class="pointer-events-none h-full w-1 bg-gray-200"></div>
                                    </div>
                                    <div class="relative z-10 inline-flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full bg-blue-500 text-white">
                                        <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="h-5 w-5" viewBox="0 0 24 24">
                                            <path d="M12 22s8-4 8-10V5l-8-3-8 3v7c0 6 8 10 8 10z"></path>
                                        </svg>
                                    </div>
                                    <div class="flex-grow pl-4">
                                        <h2 class="title-font mb-1 text-sm font-medium tracking-wider text-gray-900">PASSO 1</h2>
                                        <p class="font-laonoto leading-relaxed">
                                            Abra a câmera do seu celular ou o <br />
                                            aplicativo do banco e escaneie o <b>QR CODE </b>
                                        </p>
                                    </div>
                                </div>
                                <div class="relative flex pb-12">
                                    <div class="absolute inset-0 flex h-full w-10 items-center justify-center">
                                        <div class="pointer-events-none h-full w-1 bg-gray-200"></div>
                                    </div>
                                    <div class="relative z-10 inline-flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full bg-blue-500 text-white">
                                        <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="h-5 w-5" viewBox="0 0 24 24">
                                            <path d="M22 12h-4l-3 9L9 3l-3 9H2"></path>
                                        </svg>
                                    </div>
                                    <div class="flex-grow pl-4">
                                        <h2 class="title-font mb-1 text-sm font-medium tracking-wider text-gray-900">PASSO 2</h2>
                                        <p class="font-laonoto leading-relaxed">Verifique os dados do do pix escaneado e <b>confirme-o</b> pagamento.</p>
                                    </div>
                                </div>
                                <div class="relative flex pb-12">
                                    <div class="relative z-10 inline-flex h-10 w-10 flex-shrink-0 items-center justify-center rounded-full bg-blue-500 text-white">
                                        <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="h-5 w-5" viewBox="0 0 24 24">
                                            <circle cx="12" cy="5" r="3"></circle>
                                            <path d="M12 22V8M5 12H2a10 10 0 0020 0h-3"></path>
                                        </svg>
                                    </div>
                                    <div class="flex-grow pl-4">
                                        <h2 class="title-font mb-1 text-sm font-medium tracking-wider text-gray-900">PASSO 3</h2>
                                        <p class="font-laonoto leading-relaxed">
                                            Pronto, pagamento realizado com <span> <b>sucesso</b>!</span>.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </aside>
                    </div>
                </article>
            </section>
            <footer className='bg-background-gray text-[12px] text-center w-full mx-auto pb-[15px] text-dark-gray'>
                <hr className='divider2 mb-4 mx-8' />
                © {year} Mercado Unido. Todos os direitos reservados
            </footer>
        </div>
    )
}