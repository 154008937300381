import React, { useContext } from 'react';
import { Timer } from '../Timer';
import { AiFillCheckCircle } from "react-icons/ai";
import MainContext from '../../context/MainContext';
import AuthContext from '../../context/AuthContext';
import { formatFistPurchaseForTimer } from '../../utils/format';

export default function FinishedOrderStatusSection() {
  const { user } = useContext(AuthContext);
  const { selectedOffer, finishedOrderStatus } = useContext(MainContext);

  console.log(selectedOffer);

  if(selectedOffer.missingUnits <= 0) {
    console.log('Meta de Vendas Atingida!');
  }

  return (
    <>
      {
        finishedOrderStatus === 'first' ? (
          <>
            {/* MOBILE */}
            <div className='row-span-1 bg-white rounded-xl shadow-md mx-auto w-full lg:hidden'>
              <aside className='py-[40px] text-center px-[30px] text-[14px] text-gray-600'>
                <div className='flex flex-col items-center justify-center'>
                  <AiFillCheckCircle className='text-green w-[69px] h-[69px]' />
                  <h3 className='text-green font-bold pt-[10px] text-[23px]'>PEDIDO CONCLUÍDO!</h3>
                </div>
                <p className='font-medium pt-[30px]'>
                  Agora é só aguardar!
                </p>
                <p className='text-[15px] pt-[10px]'>
                  Parabéns! Você foi o <strong>PRIMEIRO</strong>  a comprar este produto. Você deu início ao cronômetro e <strong>falta a venda de {selectedOffer.missingUnits} produtos</strong> para que o negócio se conclua.
                </p>
                <p className='text-[15px] pt-[10px]'>
                  Mas, não se preocupe, caso o número não seja batido até o cronômetro zerar, seu dinheiro é estornado ao seu cartão.
                </p>
                <p className='text-[15px] pt-[20px]'>
                  Fique de olho, você será avisado pelo e-mail: <span className='text-primary-red underline font-medium'>{user.email}</span>
                </p>
                <Timer
                  days={selectedOffer.offerRemainingDays}
                  time={selectedOffer.offerRemainingTime}
                  firstPurchase={(selectedOffer.firstPurchase === null) ? selectedOffer.firstPurchase : formatFistPurchaseForTimer(selectedOffer.firstPurchase)}
                />
              </aside>
            </div>

            {/* DESKTOP */}
            <div className='row-span-1 col-span-3 bg-white rounded-xl shadow-md mx-auto w-full hidden lg:block'>
              <aside className='py-[40px] text-center px-[30px] text-[14px] text-gray-600'>
                <div className='flex flex-col items-center justify-center'>
                  <AiFillCheckCircle className='text-green w-[69px] h-[69px]' />
                  <h3 className='text-green font-bold pt-[10px] text-[23px]'>PEDIDO CONCLUÍDO!</h3>
                </div>
                <section className='px-[200px]'>
                  <p className='font-medium pt-[30px]'>
                    Agora é só aguardar!
                  </p>
                  <p className='text-[15px] pt-[10px]'>
                    Parabéns! Você foi o <strong>PRIMEIRO</strong>  a comprar este produto. Você deu início ao cronômetro e <strong>falta a venda de {selectedOffer.missingUnits} produtos</strong> para que o negócio se conclua.
                  </p>
                  <p className='text-[15px] pt-[10px]'>
                    Mas, não se preocupe, caso o número não seja batido até o cronômetro zerar, seu dinheiro é estornado ao seu cartão.
                  </p>
                  <p className='text-[15px] pt-[20px]'>
                    Fique de olho, você será avisado pelo e-mail: <span className='text-primary-red underline font-medium'>{user.email}</span>
                  </p>
                </section>
                <div className='mx-auto w-[288px] mt-[35px]'>
                  <Timer
                    days={selectedOffer.offerRemainingDays}
                    time={selectedOffer.offerRemainingTime}
                    firstPurchase={(selectedOffer.firstPurchase === null) ? selectedOffer.firstPurchase : formatFistPurchaseForTimer(selectedOffer.firstPurchase)}
                  />
                </div>
              </aside>
            </div>
          </>
        ) : finishedOrderStatus === 'middle'
          ? (
            <>
              {/* MOBILE */}
              <div className='row-span-1 bg-white rounded-xl shadow-md mx-auto w-full lg:hidden'>
                <aside className='py-[40px] text-center px-[30px] text-[14px] text-gray-600'>
                  <div className='flex flex-col items-center justify-center'>
                    <AiFillCheckCircle className='text-green w-[69px] h-[69px]' />
                    <h3 className='text-green font-bold pt-[10px] text-[23px]'>PEDIDO CONCLUÍDO!</h3>
                  </div>
                  <p className='font-medium pt-[30px]'>
                    Agora é só aguardar!
                  </p>
                  <p className='text-[15px] pt-[10px]'>
                    Sua compra é a <strong>n° {parseFloat(selectedOffer.neededUnities) - parseFloat(selectedOffer.missingUnits)}</strong> - faltam <strong>{selectedOffer.missingUnits} produtos</strong> para que o negócio se conclua.
                  </p>
                  <p className='text-[15px] pt-[10px]'>
                    Mas, não se preocupe, caso o número não seja batido até o cronômetro zerar, seu dinheiro é estornado ao seu cartão.
                  </p>
                  <p className='text-[15px] pt-[20px]'>
                    Fique de olho, você será avisado pelo e-mail: <span className='text-primary-red underline font-medium'>{user.email}</span>
                  </p>
                  <Timer
                    days={selectedOffer.offerRemainingDays}
                    time={selectedOffer.offerRemainingTime}
                    firstPurchase={(selectedOffer.firstPurchase === null) ? selectedOffer.firstPurchase : formatFistPurchaseForTimer(selectedOffer.firstPurchase)}
                  />
                </aside>
              </div>

              {/* DESKTOP */}
              <div className='row-span-1 col-span-3 bg-white rounded-xl shadow-md mx-auto w-full hidden lg:block'>
                <aside className='py-[40px] text-center px-[30px] text-[14px] text-gray-600'>
                  <div className='flex flex-col items-center justify-center'>
                    <AiFillCheckCircle className='text-green w-[69px] h-[69px]' />
                    <h3 className='text-green font-bold pt-[10px] text-[23px]'>PEDIDO CONCLUÍDO!</h3>
                  </div>
                  <section className='px-[200px]'>
                    <p className='font-medium pt-[30px]'>
                      Agora é só aguardar!
                    </p>
                    <p className='text-[15px] pt-[10px]'>
                      Sua compra é a <strong>n° {parseFloat(selectedOffer.neededUnities) - parseFloat(selectedOffer.missingUnits)}</strong> - faltam <strong>{selectedOffer.missingUnits} produtos</strong> para que o negócio se conclua.
                    </p>
                    <p className='text-[15px] pt-[10px]'>
                      Mas, não se preocupe, caso o número não seja batido até o cronômetro zerar, seu dinheiro é estornado ao seu cartão.
                    </p>
                    <p className='text-[15px] pt-[20px]'>
                      Fique de olho, você será avisado pelo e-mail: <span className='text-primary-red underline font-medium'>{user.email}</span>
                    </p>
                  </section>
                  <div className='mx-auto w-[288px] mt-[35px]'>
                    <Timer
                      days={selectedOffer.offerRemainingDays}
                      time={selectedOffer.offerRemainingTime}
                      firstPurchase={(selectedOffer.firstPurchase === null) ? selectedOffer.firstPurchase : formatFistPurchaseForTimer(selectedOffer.firstPurchase)}
                    />
                  </div>
                </aside>
              </div>
            </>
          )
          : (
            <>
              {/* MOBILE */}
              <div className='row-span-1 bg-white rounded-xl shadow-md mx-auto w-full lg:hidden'>
                <aside className='py-[40px] text-center px-[30px] text-[14px] text-gray-600'>
                  <div className='flex flex-col items-center justify-center'>
                    <AiFillCheckCircle className='text-green w-[69px] h-[69px]' />
                    <h3 className='text-green font-bold pt-[10px] text-[23px]'>PEDIDO CONCLUÍDO!</h3>
                  </div>
                  <p className='font-medium pt-[30px] text-green'>
                    Parabéns! Sua compra foi a última desde pedido!
                  </p>
                  <p className='text-[15px] pt-[20px]'>
                    Sua compra é a <strong>n° {selectedOffer.neededUnities}</strong> de {selectedOffer.neededUnities} itens e o cronômetro foi encerrado. Graças à sua compra o pedido foi finalizado e todos os compradores deste pedido receberão o produto!
                  </p>
                  <p className='text-[15px] pt-[20px]'>
                    Email cadastrado: <span className='text-primary-red underline font-medium'>{user.email}</span>
                  </p>
                  <Timer
                    days={selectedOffer.offerRemainingDays}
                    time={selectedOffer.offerRemainingTime}
                    firstPurchase={(selectedOffer.firstPurchase === null) ? selectedOffer.firstPurchase : formatFistPurchaseForTimer(selectedOffer.firstPurchase)}
                  />
                </aside>
              </div>

              {/* DESKTOP */}
              <div className='row-span-1 col-span-3 bg-white rounded-xl shadow-md mx-auto w-full hidden lg:block'>
                <aside className='py-[40px] text-center px-[30px] text-[14px] text-gray-600'>
                  <div className='flex flex-col items-center justify-center'>
                    <AiFillCheckCircle className='text-green w-[69px] h-[69px]' />
                    <h3 className='text-green font-bold pt-[10px] text-[23px]'>PEDIDO CONCLUÍDO!</h3>
                  </div>
                  <section className='px-[200px]'>
                    <p className='font-medium pt-[30px] text-green'>
                      Parabéns! Sua compra foi a última desde pedido!
                    </p>
                    <p className='text-[15px] pt-[20px]'>
                      Sua compra é a <strong>n° {selectedOffer.neededUnities}</strong> de {selectedOffer.neededUnities} itens e o cronômetro foi encerrado. Graças à sua compra o pedido foi finalizado e todos os compradores deste pedido receberão o produto!
                    </p>
                    <p className='text-[15px] pt-[20px]'>
                      Email cadastrado: <span className='text-primary-red underline font-medium'>{user.email}</span>
                    </p>
                  </section>
                  <div className='mx-auto w-[288px] mt-[35px]'>
                    <Timer
                      days={selectedOffer.offerRemainingDays}
                      time={selectedOffer.offerRemainingTime}
                      firstPurchase={(selectedOffer.firstPurchase === null) ? selectedOffer.firstPurchase : formatFistPurchaseForTimer(selectedOffer.firstPurchase)}
                    />
                  </div>
                </aside>
              </div>
            </>
          )
      }
    </>
  )
}