import React, { useContext } from 'react';
import MainContext from '../../context/MainContext';
import 'tw-elements';
import './style.css';

export default function Accordion () {
  const { FAQs } = useContext(MainContext);

  return (
    <div className="accordion" id="faqsAccordion">
      {
        FAQs.map((faq, index) => (
          <div key={index} className="accordion-item">
            <h2 className="accordion-header mb-0" id={`heading${faq.id}`}>
              <button className="accordion-button relative flex items-center w-full py-4 px-5 text-left bg-white rounded-none transition focus:outline-none underline font-bold text-primary-blue text-[15px] collapsed duration-150" type="button" data-bs-toggle="collapse" data-bs-target={`#collapse${faq.id}`} aria-expanded="false"
                aria-controls={`collapse${faq.id}`}>
                {faq.name}
              </button>
            </h2>
            <div id={`collapse${faq.id}`} className="accordion-collapse collapse" aria-labelledby={`heading${faq.id}`}
              data-bs-parent="#faqsAccordion">
              <div className="accordion-body py-4 px-5">
                {faq.answer}
              </div>
            </div>
          </div>
        ))
      }
    </div>
  )
}