import React, { useState } from 'react';
import { useEffect } from 'react';
import { BsStarFill } from "react-icons/bs";

export default function Rating({ propRating }) {
  function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
  }
  const [ratingNumber, setRatingNumber] = useState(propRating)

  useEffect(() => {
    if (propRating !== undefined) {
      setRatingNumber(propRating)
    }
  }, [propRating])

  return (
    <div className="flex items-center">
      {[0, 1, 2, 3, 4].map((rating) => (
        <BsStarFill
          key={rating}
          className={classNames(
            ratingNumber > rating ? 'text-yellow-400' : 'text-gray-200',
            'h-5 w-5 flex-shrink-0'
          )}
          aria-hidden="true"
        />
      ))}
      <span className='ml-[5px] font-bold'>
        ({(propRating === null || propRating === 0) ? '0' : propRating})
      </span>
    </div>
  );
}