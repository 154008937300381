import React from 'react';
import { useNavigate } from 'react-router-dom';

function ProfileLoggedOffMobile(){
  const navigate = useNavigate();

  return (
    <div>
      <button onClick={() => navigate('/login')} className="border-2 rounded-md px-4 py-1 text-white">
        Entrar
      </button>
    </div>
  )
}

export default ProfileLoggedOffMobile;