import React from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import MainBanner from '../components/MainBanner';
import SaleSection from '../components/SaleSection';

function Main(){
  return (
    <>
      <Header />
      <section className="bg-background-gray w-full">
        <MainBanner />
        <div className='max-w-[1920px] mx-auto'>
          <SaleSection category="ending_sales" title="Ofertas mais próximas de expirar" />
          <SaleSection category="last_bought" title="Últimos produtos comprados" />
        </div>
      </section>
      <Footer />
    </>
  );
}

export default Main;  