import React from 'react';
import { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import MainContext from '../context/MainContext';
import PuzzleBanner from './PuzzleBanner';
import AuthContext from '../context/AuthContext';


function MainBanner() {
  const navigate = useNavigate();
  const {
    loggedInStatus, mainBannerMessageOn, mainBannerMessageOff
  } = useContext(MainContext);

  const { isLogged } = useContext(AuthContext);

  return (
    <article className="w-full rounded-b-md relative">
      <div className='w-full bg-gradient-to-r from-sky-500 to-fuchsia-500 h-[261px] lg:h-[434px]'></div>
      {
          isLogged ? (
            <>
              {/* DESKTOP */}
              <div className="hidden lg:block absolute top-[100px] right-[330px] w-[150px] md:w-[400px] text-left">
                <h3 className="text-primary-blue md:text-primary-blue text-[17px] lg:text-[21px] pb-[11px] font-medium">
                {mainBannerMessageOn.main}
                </h3>
                <h2 className="text-primary-blue md:text-primary-blue font-bold text-[21px] lg:text-[35px] pb-[45px]">
                  {mainBannerMessageOn.sub}
                </h2>
                <button onClick={() => navigate('/login')} className="hover:shadow-md font-bold w-1/2 bg-primary-blue rounded-xl px-4 py-[12px] text-white hover:bg-tertiary-blue hover:border-tertiary-blue duration-150 mb-[40px] text-[18px]">
                  {mainBannerMessageOn.button}
                </button>
              </div>
              {/* MOBILE */}
              <div className="lg:hidden absolute top-[40px] left-[30px] max-w-[200px] md:w-[400px] text-left">
                <h3 className="text-primary-blue md:text-primary-blue text-[17px] lg:text-[21px] pb-[22px] font-medium">
                {mainBannerMessageOn.main}
                </h3>
                <h2 className="text-primary-blue md:text-primary-blue font-bold text-[21px] lg:text-[35px] pb-[22px]">
                  {mainBannerMessageOn.sub}
                </h2>
                <button onClick={() => navigate('/social-login')} className="hover:shadow-md font-bold w-full bg-primary-blue rounded-xl px-4 py-[12px] text-white hover:bg-tertiary-blue hover:border-tertiary-blue duration-150 mb-[40px] text-[18px]">
                  Entrar
                </button>
              </div>            
            </>            
          ) : (
            <>
              {/* DESKTOP */}
              <div className="hidden lg:block absolute top-[100px] right-[330px] w-[150px] md:w-[400px] text-left">
                <h3 className="text-primary-blue md:text-primary-blue text-[17px] lg:text-[21px] pb-[11px] font-medium">
                {mainBannerMessageOff.main}
                </h3>
                <h2 className="text-primary-blue md:text-primary-blue font-bold text-[21px] lg:text-[35px] pb-[45px]">
                  {mainBannerMessageOff.sub}
                </h2>
                <button onClick={() => navigate('/social-login')} className="hover:shadow-md font-bold w-1/2 bg-primary-blue rounded-xl px-4 py-[12px] text-white hover:bg-tertiary-blue hover:border-tertiary-blue duration-150 mb-[40px] text-[18px]">
                  {mainBannerMessageOff.button}
                </button>
              </div>
              {/* MOBILE */}
              <div className="lg:hidden absolute top-[40px] left-[30px] max-w-[200px] md:w-[400px] text-left">
                <h3 className="text-primary-blue md:text-primary-blue text-[17px] lg:text-[21px] pb-[22px] font-medium">
                {mainBannerMessageOff.main}
                </h3>
                <h2 className="text-primary-blue md:text-primary-blue font-bold text-[21px] lg:text-[35px] pb-[22px]">
                  {mainBannerMessageOff.sub}
                </h2>
                <button onClick={() => navigate('/login')} className="hover:shadow-md font-bold w-full bg-primary-blue rounded-xl px-4 py-[12px] text-white hover:bg-tertiary-blue hover:border-tertiary-blue duration-150 mb-[40px] text-[18px]">
                  Entrar
                </button>
              </div>            
            </>
          )
        }
      <div className="bg-primary-blue max-h-[180px]">
        <p className="text-white font-bold text-center py-[10px] text-[24px] px-[20px]">Espaço para bloco de texto aqui! Confira nossas novidades</p>
      </div>
      {
        isLogged ? (
            <></>
          ) : (
          <PuzzleBanner />
        )
      }
    </article>
  )
}

export default MainBanner;
