import React, { useContext } from 'react';
import ProfileMenu from '../ProfileMenu';
import { MdArrowDropDown } from "react-icons/md";
import { Link } from "react-router-dom";
import MainContext from '../../context/MainContext';
import AuthContext from '../../context/AuthContext';

function ProfileLoggedIn() {
  const { profileMenu, setProfileMenu } = useContext(MainContext);
  const { user, shoppingCart } = useContext(AuthContext);

  return (
    <>
      <button onClick={() => setProfileMenu(!profileMenu)} className="flex flex-row items-center hover:bg-tertiary-red py-1 rounded-md px-1 mr-1 my-1 text-white relative">
        <img className="rounded-full border-white border-2 h-8 w-8 object-cover" src={user.profilePictureUrl} alt="Profile Pic" />
        <div className="flex flex-col text-left pl-2 ">
          <p className="text-xs text-gray-300 text-2xs">Bem vindo(a),</p>
          <p className="text-sm font-bold -mt-1">{user.firstName}</p>
        </div>
        <MdArrowDropDown className="mx-1 text-white text-xl" />
        {profileMenu === true ? (<ProfileMenu />) : null}
      </button>
    </>
  )
}

export default ProfileLoggedIn;