import React, { useContext } from 'react';
import { useEffect } from 'react';
import { AiOutlineMinus, AiOutlinePlus } from "react-icons/ai";
import MainContext from '../../context/MainContext';


export default function QuantitySelector (props) {
  const { counter, setCounter } = useContext(MainContext);
  
  useEffect(() => {
    if (counter < 1) {
      setCounter(1)
    }

    if(counter != 1 && counter > props.missingUnits) {
      setCounter(counter - 1);
    }
  }, [counter])

  return (
    <div className='flex flex-row items-center'>
      <p className='text-[15px] font-bold text-primary-blue pr-[12px]'>Quantidade</p>
        <div className='flex flex-row justify-around items-center items-center py-1 border-[2px] rounded-xl border-primary-blue w-[90px] h-[40px] gap-2'>
          <h2 className='inline-block hover:cursor-pointer hover:scale-105 duration-150 hover:text-primary-red' onClick={() => setCounter(counter - 1)}><AiOutlineMinus /></h2>
          <h2 className='inline-block font-bold text-primary-blue text-[15px]'>{ counter }</h2>
          <h2 className='inline-block hover:cursor-pointer hover:scale-105 duration-150 hover:text-primary-red' onClick={() => setCounter(counter + 1)}><AiOutlinePlus /></h2>
        </div>
    </div>
  )
}