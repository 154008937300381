import axios from 'axios';
import { url } from './api';

export const getItens = async (id, token) => {
    try {
        const response = await axios.get(url + `/cart/user/${id}`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });

        return (response.status === 200) ? response : false;
    } catch (error) {
        console.log(error);
        return false;
    }
}

export const addItem = async (quantity, price, id_user, id_offer, token) => {
    try {
        const response = await axios.post(url + '/carts', {
            quantity: quantity,
            price: price,
            id_user: id_user,
            id_offer: id_offer,
        }, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });

        return (response.status === 201) ? response : false;
    } catch (error) {
        console.log(error);
        return false;
    }
}

export const deleteItem = async (id, token) => {
    try {
        const response = await axios.delete(url + '/carts/' + id, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });

        return (response.status === 200) ? response : false;
    } catch (error) {
        console.log(error);
        return false;
    }
}