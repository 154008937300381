import React, { useContext } from 'react';
import Footer from '../components/Footer';
import Header from '../components/Header';
import SaleSection from '../components/SaleSection';
import SearchResult from '../components/SearchResult';
import MainContext from '../context/MainContext';

function SearchPage() {
  const {
    offerSearch,
  } = useContext(MainContext);

  return (
    <>
      <Header />
      <section className="bg-background-gray w-full">
        <article className='mx-auto w-full text-center'>
          <h4 className='pt-[55px] text-[20px] text-gray-600'>
            Resultados para <strong>"{ offerSearch }"</strong>
          </h4>
          <p className='text-gray-600 text-[15px] pt-[8px]'>
            8 Produtos encontrados
          </p>
        </article>
        <SearchResult />
        <SaleSection category="ending_sales" title="Ofertas mais próximas de expirar" />
        <SaleSection category="last_bought" title="Últimos produtos comprados" />
      </section>
      <Footer />
    </>
    
  )
}

export default  SearchPage;