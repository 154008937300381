import React, { useState, useEffect } from 'react';
import PaymentContext from './PaymentContext';
import * as payment from './../services/payment.js';
import * as charge from './../services/charge.js';
import axios from 'axios';
import { useContext } from 'react';
import AuthContext from './AuthContext';

function PaymentProvider({ children }) {
    const [ qrCodePix, setQrCodePix ] = useState(null); 
    const [ boleto, setBoleto ] = useState(null);

    useEffect(() => {
        const qrCodePix = localStorage.getItem('app-mercado-unido-qrCodePix');
        const boleto = localStorage.getItem('app-mercado-unido-boleto');
    
        if(qrCodePix) {
            setQrCodePix(JSON.parse(qrCodePix));

            return;
        }

        if(boleto) {
            setBoleto(JSON.parse(boleto));

            return;
        }
    }, []);

    async function getBoleto(idCharge, token) {
        const response = await charge.getBoleto(idCharge, token);
        console.log(response);

        return (response.status === 200) ? response : false;
    }

    async function generateQrCodePix(
        token, name_customer, email_customer, tax_id_customer, area_phone, number_phone, name_item, quantity_item, unit_amount_item,
        amount_qr_code, street_shipping, number_shipping, complement_shipping, locality_shipping, city_shipping, region_code_shipping, postal_code_shipping,
        id_product, id_cart, id_offer
    ) {
        const response = await payment.generateQrCodePix(
            token, name_customer, email_customer, tax_id_customer, area_phone, number_phone, name_item, quantity_item, unit_amount_item,
            amount_qr_code, street_shipping, number_shipping, complement_shipping, locality_shipping, city_shipping, region_code_shipping, postal_code_shipping,
            id_product, id_cart, id_offer
        );

        if(response.status === 201) {
            setQrCodePix(response.data);
            localStorage.setItem('app-mercado-unido-qrCodePix', JSON.stringify(response.data));

            return response;
        }
    }

    async function generateBoleto(
        token, value, name_customer, email_customer, tax_id_customer, street_shipping, number_shipping,
        locality_shipping, city_shipping, region_code_shipping, postal_code_shipping, id_product, id_cart, id_offer
    ) {
        const response = await payment.generateBoleto(
            token, value, name_customer, email_customer, tax_id_customer, street_shipping, number_shipping,
            locality_shipping, city_shipping, region_code_shipping, postal_code_shipping, id_product, id_cart, id_offer
        );

        if(response.status === 201) {
            setBoleto(response.data);
            localStorage.setItem('app-mercado-unido-boleto', JSON.stringify(response.data));

            return response;
        }
    }

    async function payWithCreditCard(token, value, installments, number, exp_month, exp_year, security_code, holder, id_cart, id_offer, id_product) {
        const response = await payment.payWithCreditCard(token, value, installments, number, exp_month, exp_year, security_code, holder, id_cart, id_offer, id_product);

        if(response.status === 201) {
            return response;
        }

        return false;
    }

    const numParcelas = 10
    const [ toAlterPersonalData, setToAlterPersonalData ] = useState(false);
    const [ toAlterAddressData, setToAlterAddressData ] = useState(false);

    const context = {
        generateQrCodePix,
        generateBoleto,
        payWithCreditCard,
        boleto, setBoleto, getBoleto,
        qrCodePix, setQrCodePix,
        numParcelas,
        toAlterPersonalData, setToAlterPersonalData,
        toAlterAddressData, setToAlterAddressData
    }

    return (
        <PaymentContext.Provider value={context}>
            {children}
        </PaymentContext.Provider>
    )
}

export default PaymentProvider;