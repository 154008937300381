import React, { useContext } from 'react';
import { Tab } from '@headlessui/react';
import MainContext from '../context/MainContext';
import { useState } from 'react';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function ImageCarousel () {
  const { selectedOffer } = useContext(MainContext);
  const [selectedImage, setSelectedImage] = useState(0);

  return (
    <>
      {/* MOBILE */}
      <div
        id="carouselExampleCrossfade"
        className="carousel slide carousel-fade relative md:hidden"
        data-bs-ride="carousel"
      >
        <div className="carousel-indicators absolute right-0 bottom-0 left-0 flex justify-center p-0 mb-4">
          {
            selectedOffer.image.map((image, index) => (
              <button
                key={ index }
                type="button"
                data-bs-target="#carouselExampleCrossfade"
                data-bs-slide-to={index}
                className={classNames(selectedImage === index ? 'active': '')}
                aria-current="true"
                aria-label={`"Slide ${index + 1}`}
              ></button>              
            ))
          }
        </div>
        <div className="carousel-inner relative w-full overflow-hidden">
          {
            selectedOffer.image.map((image, index) => {
              return (
                <div key={index} className={classNames(selectedImage === index ? 'active' : '', "carousel-item  float-left w-full")}>
                  <img
                    src={ image.urlImg }
                    className="block w-[355px] h-[250px] object-cover"
                    alt={ 'imagem produto' }
                  />
                </div>
              )})
          }
        </div>
        <button
          onClick={() => {
            if (selectedImage === 0) {
              return
            }

            setSelectedImage(selectedImage - 1)
          }}
          className="carousel-control-prev absolute top-0 bottom-0 flex items-center justify-center p-0 text-center border-0 hover:outline-none hover:no-underline focus:outline-none focus:no-underline left-0"
          type="button"
          data-bs-target="#carouselExampleCrossfade"
          data-bs-slide="prev"
        >
          <span className="carousel-control-prev-icon inline-block bg-no-repeat" aria-hidden="true"></span>
          <span className="visually-hidden">Previous</span>
        </button>
        <button
          onClick={() => {
            if (selectedImage === (selectedOffer.image.length - 1)) {
              console.log(selectedImage);
              console.log(selectedOffer.image.length - 1);
              setSelectedImage(0)
              return
            }

            setSelectedImage(selectedImage + 1)
          }}
          className="carousel-control-next absolute top-0 bottom-0 flex items-center justify-center p-0 text-center border-0 hover:outline-none hover:no-underline focus:outline-none focus:no-underline right-0"
          type="button"
          data-bs-target="#carouselExampleCrossfade"
          data-bs-slide="next"
        >
          <span className="carousel-control-next-icon inline-block bg-no-repeat" aria-hidden="true"></span>
          <span className="visually-hidden">Next</span>
        </button>
      </div>

      {/* DESKTOP */}
      <Tab.Group as="div" className="hidden md:flex flex-col-reverse w-full">
      {/* Image selector */}
      <div className="mx-auto mt-[16px] hidden w-full sm:block lg:max-w-none">
        <Tab.List className="grid grid-cols-6 gap-x-[20px]">
          {
            selectedOffer.image.map((image) => (
            <Tab
              key={image.id}
              className="relative flex h-[70px] w-[70px] cursor-pointer items-center justify-center rounded-md bg-white text-sm font-medium uppercase text-gray-900 hover:bg-gray-50"
            >
              <>
                <span className="sr-only"> {'name da imagem'} </span>
                <span className="absolute inset-0 overflow-hidden rounded-md">
                  <img src={image.urlImg} alt="" className="h-full w-full object-cover object-center" />
                </span>
                <span
                  className='pointer-events-none absolute inset-0 rounded-md ring-2 ring-offset-2 ring-transparent'
                  aria-hidden="true"
                />
                </>
            </Tab>
            ))
          }
        </Tab.List>
      </div>

      <Tab.Panels className="w-full object-cover">
        {selectedOffer.image.map((image) => (
          <Tab.Panel key={image.id}>
            <img
              src={image.urlImg}
              alt={'imagem do produto'}
              className="h-[323px] w-[390px] object-cover sm:rounded-lg"
            />
          </Tab.Panel>
        ))}
      </Tab.Panels>
      </Tab.Group>    
    </>
  )
}