import React from 'react';
import CompanyCard from './small_components/CompanyCard';

export default function CompaniesBar (price) {
  return (
    <aside className='bg-primary-red lg:flex w-full h-[101px] lg:mb-[0px]' >
      <div className='relative flex items-center flex-col lg:flex-row w-full max-w-[1280px] mx-auto'>
        <div className='h-full overflow-x-scroll scroll whitespace-nowrap scroll-smooth w-full scrollbar-hide'>
          <CompanyCard />
          <CompanyCard />
          <CompanyCard />
          <CompanyCard />
          <CompanyCard />
        </div>
        <div className='lg:absolute right-0 flex flex-col text-center items-center justify-center text-white py-4 mx-auto w-full lg:w-[428px] bg-primary-red h-[95px] lg:h-[101px]'>
          <p className='text-[14px]'>Média de preço deste produto no varejo</p>
          <h3 className='font-bold text-[26px]'>R$ 2.999,00</h3>
        </div>
      </div>
    </aside>
  )
}