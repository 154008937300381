import { useContext } from "react";
import { BsStarFill } from "react-icons/bs";
import MainContext from "../context/MainContext";
import { useState } from "react";
import { useEffect } from "react";

const reviews = {
  average: 4,
  totalCount: 1624,
  counts: [
    { rating: 5, count: 1019 },
    { rating: 4, count: 162 },
    { rating: 3, count: 97 },
    { rating: 2, count: 199 },
    { rating: 1, count: 147 },
  ],
  featured: [
    {
      id: 1,
      rating: 5,
      content: `
        <p>This is the bag of my dreams. I took it on my last vacation and was able to fit an absurd amount of snacks for the many long and hungry flights.</p>
      `,
      author: 'Emily Selman',
      avatarSrc:
        'https://images.unsplash.com/photo-1502685104226-ee32379fefbe?ixlib=rb-=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=8&w=256&h=256&q=80',
    },
  ],
}

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function RatingSection() {
  const { selectedOffer } = useContext(MainContext);

  const [ totalRate, setTotalRate ] = useState(0);

  useEffect(() => {
    const newTotalRate = selectedOffer.rate.reduce((total, review) => {
      return total + parseFloat(review.rate);
    }, 0);
  
    setTotalRate(parseFloat(newTotalRate) / selectedOffer.rate.length);
  }, [selectedOffer]);

  return (
    <>
      {/* DESKTOP */}
      <div className="bg-white hidden md:flex flex-col items-between">
        <div className="px-8 lg:grid lg:max-w-7xl lg:grid-cols-12 lg:gap-x-8 py-8 border-gray-200 border-b-[2px]">
          <div className="lg:col-span-8">
            <h2 className='font-bold text-dark-gray px-6 text-[21px]'>Avaliações</h2>
            <div className="mt-3 flex items-center">
              <div className='flex flex-col'>
                <h4 className='text-dark-gray font-bold text-7xl'>{(totalRate).toFixed(1)}</h4>
                <div className="flex items-center mt-[4px]">
                  {[0, 1, 2, 3, 4].map((rate) => (
                    <BsStarFill
                      key={rate}
                      className={classNames(
                        totalRate > rate ? 'text-yellow-400' : 'text-gray-300',
                        'flex-shrink-0 h-5 w-5'
                      )}
                      aria-hidden="true"
                    />
                  ))}
                  <p className="ml-2 text-sm text-gray-900">Baseado em {selectedOffer.rate.length} avaliações</p>
                </div>
                <p className="sr-only">{totalRate} de 5 estrelas</p>
              </div>
            </div>
          </div>
          <div className="lg:col-span-4">
            {/* <div className="mt-6">
              <dl className="space-y-3">
                {reviews.counts.map((count) => (
                  <div key={count.rating} className="flex items-center text-sm">
                    <dt className="flex flex-1 items-center">
                      <p className="w-3 font-medium text-gray-900">
                        {count.rating}
                        <span className="sr-only"> star reviews</span>
                      </p>
                      <div aria-hidden="true" className="ml-1 flex flex-1 items-center">
                        <BsStarFill
                          className={classNames(
                            count.count > 0 ? 'text-yellow-400' : 'text-gray-300',
                            'flex-shrink-0 h-5 w-5'
                          )}
                          aria-hidden="true"
                        />

                        <div className="relative ml-3 flex-1">
                          <div className="h-3 rounded-full border border-gray-200 bg-gray-100" />
                          {count.count > 0 ? (
                            <div
                              className="absolute inset-y-0 rounded-full border border-yellow-400 bg-yellow-400"
                              style={{ width: `calc(${count.count} / ${reviews.totalCount} * 100%)` }}
                            />
                          ) : null}
                        </div>
                      </div>
                    </dt>
                    <dd className="ml-3 w-10 text-right text-sm tabular-nums text-gray-900">
                      {Math.round((count.count / reviews.totalCount) * 100)}%
                    </dd>
                  </div>
                ))}
              </dl>
            </div> */}
          </div>
        </div>
        <div className="mt-16 lg:col-span-7 lg:col-start-6 lg:mt-0 py-8 px-6">
          <h3 className="sr-only">Avaliações Recentes</h3>

          <div className="flow-root">
            <div className="-my-12 divide-y divide-gray-200">
              {selectedOffer.rate.map((review) => (
                <div key={review.id} className="py-12">
                  <div className="flex items-center">
                    <img src={review.image_url} alt={`${review.name}.`} className="h-12 w-12 rounded-full" />
                    <div className="ml-4">
                      <h4 className="text-sm font-bold text-gray-900">{review.name}</h4>
                      <div className="mt-1 flex items-center">
                        {[0, 1, 2, 3, 4].map((rating) => (
                          <BsStarFill
                            key={rating}
                            className={classNames(
                              review.rate > rating ? 'text-yellow-400' : 'text-gray-300',
                              'h-5 w-5 flex-shrink-0'
                            )}
                            aria-hidden="true"
                          />
                        ))}
                      </div>
                      <p className="sr-only">{review.rate} out of 5 stars</p>
                    </div>
                  </div>

                  <div
                    className="mt-4 space-y-6 text-base italic text-gray-600"
                    dangerouslySetInnerHTML={{ __html: review.description }}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      {/* MOBILE */}
      <div className="bg-white md:hidden">
        <div className="mx-auto max-w-2xl px-4 sm:px-6 lg:grid lg:max-w-7xl lg:grid-cols-12 lg:gap-x-8">
          <div className="lg:col-span-4">
            <h2 className='font-bold text-dark-gray px-6 text-[21px] pt-8'>Avaliações</h2>
            <div className="mt-3 flex items-center">
              <div className='flex flex-col'>
                <h4 className='text-dark-gray font-bold text-[68px] py-2'>
                  {
                    (totalRate).toFixed(1)
                  }
                </h4>
                <div className="flex items-center">
                  {[0, 1, 2, 3, 4].map((rating) => (
                    <BsStarFill
                      key={rating}
                      className={classNames(
                        totalRate > rating ? 'text-yellow-400' : 'text-gray-300',
                        'flex-shrink-0 h-5 w-5'
                      )}
                      aria-hidden="true"
                    />
                  ))}
                  <p className="ml-2 text-sm text-gray-900">Baseado em {selectedOffer.rate.length} avaliações</p>
                </div>
                <p className="sr-only">{totalRate} de 5 estrelas</p>
              </div>
            </div>

            {/*<div className="mt-6">
              <dl className="space-y-3">
                {reviews.counts.map((count) => (
                  <div key={count.rating} className="flex items-center text-sm">
                    <dt className="flex flex-1 items-center">
                      <p className="w-3 font-medium text-gray-900">
                        {count.rating}
                        <span className="sr-only"> star reviews</span>
                      </p>
                      <div aria-hidden="true" className="ml-1 flex flex-1 items-center">
                        <BsStarFill
                          className={classNames(
                            count.count > 0 ? 'text-yellow-400' : 'text-gray-300',
                            'flex-shrink-0 h-5 w-5'
                          )}
                          aria-hidden="true"
                        />

                        <div className="relative ml-3 flex-1">
                          <div className="h-3 rounded-full border border-gray-200 bg-gray-100" />
                          {count.count > 0 ? (
                            <div
                              className="absolute inset-y-0 rounded-full border border-yellow-400 bg-yellow-400"
                              style={{ width: `calc(${count.count} / ${reviews.totalCount} * 100%)` }}
                            />
                          ) : null}
                        </div>
                      </div>
                    </dt>
                    <dd className="ml-3 w-10 text-right text-sm tabular-nums text-gray-900">
                      {Math.round((count.count / reviews.totalCount) * 100)}%
                    </dd>
                  </div>
                ))}
              </dl>
            </div>*/}
          </div>

          <div className="mt-16 lg:col-span-7 lg:col-start-6 lg:mt-0">
            <h3 className="sr-only">Recent reviews</h3>

            <div className="flow-root">
              <div className="-my-12 divide-y divide-gray-200">
                {selectedOffer.rate.map((review) => (
                  <div key={review.id} className="py-12">
                    <div className="flex items-center">
                      <img src={review.image_url} alt={`${review.name}.`} className="h-12 w-12 rounded-full" />
                      <div className="ml-4">
                        <h4 className="text-sm font-bold text-gray-900">{review.name}</h4>
                        <div className="mt-1 flex items-center">
                          {[0, 1, 2, 3, 4].map((rating) => (
                            <BsStarFill
                              key={rating}
                              className={classNames(
                                review.rate > rating ? 'text-yellow-400' : 'text-gray-300',
                                'h-5 w-5 flex-shrink-0'
                              )}
                              aria-hidden="true"
                            />
                          ))}
                        </div>
                        <p className="sr-only">{review.rate} de 5 estrelas</p>
                      </div>
                    </div>

                    <div
                      className="mt-4 space-y-6 text-base italic text-gray-600"
                      dangerouslySetInnerHTML={{ __html: review.description }}
                    />
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
