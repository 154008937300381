import React, { useContext } from 'react';
import ProfileMenu from '../ProfileMenu';
import { MdArrowDropDown } from "react-icons/md";
import { Link } from "react-router-dom";
import MainContext from '../../context/MainContext';
import AuthContext from '../../context/AuthContext';

function ProfileLoggedInMobile() {
  const { profileMenu, setProfileMenu } = useContext(MainContext);
  const { user, shoppingCart } = useContext(AuthContext);

  return (
    <>
      <button onClick={() => setProfileMenu(!profileMenu)} className="flex flex-row items-center p-1 my-1 text-white relative">
        <img className="rounded-full border-white border-2 h-7 w-7 object-cover" src={user.profilePictureUrl} alt="Profile Pic" />
        <div className="flex flex-col text-left pl-2">
          <p className="text-xs text-gray-300">Bem vindo(a),</p>
          <p className="font-bold -mt-1 text-sm">{user.firstName}</p>
        </div>
        <MdArrowDropDown className="text-white text-2xl" />
        {profileMenu === true ? (<ProfileMenu />) : null}
      </button>
    </>

  )
}

export default ProfileLoggedInMobile;