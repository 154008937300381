import React from 'react';
import Footer from '../components/Footer';
import Header from '../components/Header';
import { AiOutlineArrowLeft } from "react-icons/ai";
import { useNavigate } from 'react-router-dom';
import { SearchIcon } from '@heroicons/react/solid';
import Accordion from '../components/Accordion';

function PrivacyPolicies() {
  const navigate = useNavigate();

  return (

    <>
      <Header />
      <section className="bg-background-gray py-10">
        <article className="bg-white mx-auto rounded-md w-5/6 shadow-md px-8 py-12 lg:pb-[130px] lg:pt-[38px] lg:px-[90px] max-w-screen-lg">
          <div className='flex flex-row items-center hover:cursor-pointer hover:opacity-80' onClick={() => navigate(-1)}>
            <AiOutlineArrowLeft className='text-primary-blue font-bold' />
            <p className='px-2 text-primary-blue underline'>Voltar</p>
          </div>
          <h3 className="text-primary-blue text-left w-full font-bold text-[35px] py-[10px]">Central de Ajuda</h3>
          <p className='text-gray-500 text-[18px] pb-[30px]'>Canal exclusivo para tirar suas dúvidas sobre nosso sistema</p>
          <div className="text-sm flex-auto pb-[30px]">
            <div className="w-full">
              <label htmlFor="search" className="sr-only">
                Digite sua dúvida aqui
              </label>
              <div className="relative">
                <input
                  id="search"
                  name="search"
                  className="block w-full h-[40px] mx-auto pr-10 pl-3 py-1 border border-gray-300 rounded-lg leading-5 bg-white text-gray-300 placeholder-gray-500 focus:outline-none focus:bg-white focus:border-white focus:ring-secondary-red focus:text-gray-900 text-sm"
                  placeholder="Digite sua dúvida aqui"
                  type="search"
                />
                <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                  <SearchIcon className="h-5 w-5 text-primary-blue" aria-hidden="true" />
                </div>
              </div>
            </div>
          </div>
          <Accordion />
        </article>
      </section>
      <Footer />
    </>

  )
}

export default PrivacyPolicies;