import React from 'react';
import { Link } from 'react-router-dom';
import OfferCard from './OfferCard';
import '../pages/css/ProductPage.css';
import { useContext } from 'react';
import MainContext from '../context/MainContext';

function SearchResult() {
  const { setSelectedOffer, offers, offerSearch } = useContext(MainContext);

  return (
    <div className="py-8 mt-2 flex flex-col items-center justify-center mx-auto">
      <div className='text-center'>
        <select
          id="filter"
          name="filter"
          className="block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-primary-blue focus:outline-none focus:ring-primary-blue sm:text-sm mb-[35px]"
        >
          <option>Ordenar por mais relevante</option>
          <option>Ordenar por menor tempo de expiração</option>
          <option>Ordenar por unidades faltando</option>
        </select>
      </div>
      <div className='w-full mx-auto max-w-[1280px] flex justify-left flex-wrap'>
        {


          offers.filter(offer =>
            offer.title.toLowerCase().includes(offerSearch.toLowerCase())
          )
            .map(offer => {
              const newTotalRate = offer.rate.reduce((total, review) => {
                return total + parseFloat(review.rate);
              }, 0);

              return (
                (
                  <Link key={offer.id} to={`/product/${offer.id}`}>
                    <OfferCard
                      onClick={() => setSelectedOffer(offer)}
                      imgSrc={offer.image[0].urlImg}
                      name={offer.product.name}
                      retailPrice={offer.retailPrice}
                      price={offer.price}
                      down={offer.down}
                      missingUnits={offer.missingUnits}
                      neededUnities={offer.neededUnities}
                      offerRemainingDays={offer.offerRemainingDays}
                      offerRemainingTime={offer.offerRemainingTime}
                      rate={parseFloat(newTotalRate) / offer.rate.length}
                    />
                  </Link>
                )
              )
            })
        }
      </div>
    </div>
  )
}

export default SearchResult;