import React, { useContext } from 'react';
import MainContext from '../context/MainContext';
import { useNavigate } from 'react-router-dom';
import { formatMoney } from '../utils/format';
import { IMaskInput } from 'react-imask';

import boleto_img from './../assets/icons/boleto-barcode.svg'
import visa_img from './../assets/icons/visa.svg'
import mastercard_img from './../assets/icons/mastercard.svg'
import elo_img from './../assets/icons/elo.svg';
import hiper_img from './../assets/icons/hiper.svg';
import amex_img from './../assets/icons/amex-american-express.svg';
import hipercard_img from './../assets/icons/hipercard-v2.svg';
import pix_img from './../assets/icons/pix.svg';
import PaymentContext from '../context/PaymentContext';
import AuthContext from '../context/AuthContext';
import { useState } from 'react';
import { useEffect } from 'react';

export default function Checkout() {
    const {
        stepper, setStepper,
        deliveryFee, setSelectedOffer,
        setSelectedImage, setSelectedCart,
        offers, setFinishedOrderStatus
    } = useContext(MainContext);
    const { generateQrCodePix, generateBoleto, numParcelas, payWithCreditCard } = useContext(PaymentContext);
    const { user, token, checkoutItem, getItensCart, getOrders } = useContext(AuthContext);

    const currentYear = new Date().getFullYear();
    const [processing, setProcessing] = useState(false);
    const [selectedOption, setSelectedOption] = useState(null);
    const navigate = useNavigate();

    const [holder, setHolder] = useState('');
    const [numberCard, setNumberCard] = useState('');
    const [month, setMonth] = useState('01');
    const [year, setYear] = useState(currentYear);
    const [securityCode, setSecurityCode] = useState('');
    const [installment, setInstallment] = useState('1');

    function calcularParcelas() {
        let installments = []

        for (let i = 1; i <= numParcelas; i++) {
            installments.push((checkoutItem.price / i).toFixed(2));
        }

        return installments;
    }

    function yearsValidadeCreditCard() {
        let years = [];

        for (let i = 0; i <= 10; i++) {
            const yearValue = currentYear + i;
            years.push(yearValue);
        }

        return years;
    }

    const handleOptionChange = (event) => {
        setSelectedOption(event.target.id);
    };

    const payNow = async (e) => {
        e.preventDefault();

        if (selectedOption === null) {
            alert('Nenhum item Selecionado');
        } else {
            try {
                setProcessing(true);

                if (selectedOption === "pix") {
                    let name_customer = user.firstName + " " + user.lastName;
                    let area_phone = user.phone.substring(0, 2);
                    let number_phone = user.phone.substring(2);
                    let amount_qr_code = parseFloat(checkoutItem.price) + parseFloat(deliveryFee);

                    const response = await generateQrCodePix(
                        token,
                        name_customer,
                        user.email,
                        user.cpf,
                        area_phone,
                        number_phone,
                        checkoutItem.offer.title,
                        parseFloat(checkoutItem.quantity),
                        parseFloat(checkoutItem.offer.price),
                        parseFloat(String(amount_qr_code).replace(/[.,]/g, '')),
                        user.mainAddress.street,
                        user.mainAddress.number,
                        user.mainAddress.complement,
                        user.mainAddress.district,
                        user.mainAddress.city,
                        user.mainAddress.state,
                        user.mainAddress.cep,
                        checkoutItem.offer.product.id,
                        checkoutItem.id,
                        checkoutItem.offer.id
                    );

                    if (response.status === 201) {
                        await getItensCart(user.id, token);
                        await getOrders(token);

                        navigate(`/payment/pix/${checkoutItem.offer.id}`);
                    } else {
                        alert(response.error_message);
                    }
                } else if (selectedOption === "boleto_bancario") {
                    let name_customer = user.firstName + " " + user.lastName;
                    let amount = parseFloat(checkoutItem.price) + parseFloat(deliveryFee);

                    const response = await generateBoleto(
                        token,
                        parseFloat(String(amount).replace(/[.,]/g, '')),
                        name_customer,
                        user.email,
                        user.cpf,
                        user.mainAddress.street,
                        user.mainAddress.number,
                        user.mainAddress.district,
                        user.mainAddress.city,
                        user.mainAddress.state,
                        user.mainAddress.cep,
                        checkoutItem.offer.product.id,
                        checkoutItem.id,
                        checkoutItem.offer.id
                    );

                    if (response.status === 201) {
                        await getItensCart(user.id, token);
                        await getOrders(token);

                        navigate(`/payment/boleto/${checkoutItem.offer.id}`);
                    } else {
                        alert('erro boleto');
                    }
                } else if (selectedOption === "cartao_credito") {
                    if (holder === "" || numberCard === "" || month === "" || year === "" || securityCode === "" || installment === "") {
                        alert('Campos vazios! ');
                    } else {
                        let amount = parseFloat(checkoutItem.price) + parseFloat(deliveryFee);

                        const response = await payWithCreditCard(
                            token,
                            parseFloat(String(amount).replace(/[.,]/g, '')),
                            installment,
                            numberCard.replace(/\s/g, ''),
                            month,
                            year,
                            securityCode,
                            holder,
                            checkoutItem.id,
                            checkoutItem.offer.id,
                            checkoutItem.offer.product.id
                        )

                        if (response.status === 201) {
                            setSelectedImage(response.data.images)
                            setSelectedOffer(response.data.offer);
                            setSelectedCart(response.data.cart);

                            const offer = offers.filter(offer => offer.id === response.data.offer.id);

                            (offer[0].firstPurchase === null) ? setFinishedOrderStatus('first') : setFinishedOrderStatus('middle');
                            if (response.data.offer.missingUnits === 0) setFinishedOrderStatus('last');

                            await getItensCart(user.id, token);
                            await getOrders(token);

                            navigate(`/finishorder/${checkoutItem.offer.id}`);
                        }
                    }
                }
            } catch (error) {
                console.log(error);
            } finally {
                setProcessing(false);
            }
        }
    }

    return (
        <>
            <div class="-mx-3 md:flex items-start pt-[20px]">
                <div class="px-3 md:w-7/12 lg:pr-10">
                    <div class="w-full mx-auto text-gray-800 font-light mb-6 border-b border-gray-200 pb-6">
                        <div class="w-full flex items-center">
                            <div class="overflow-hidden rounded-lg w-16 h-16 bg-gray-50">
                                <img src={checkoutItem.offer.product.images[0].urlImg} alt="" />
                            </div>
                            <div class="flex-grow pl-3 text-left">
                                <h6 class="font-semibold uppercase text-gray-600">{checkoutItem.offer.title}</h6>
                                <p class="text-gray-400">x {checkoutItem.quantity}</p>
                            </div>
                            <div>
                                <span class="font-semibold text-gray-600 text-xl">{formatMoney(checkoutItem.price)}</span>
                            </div>
                        </div>
                    </div>
                    <div class="mb-6 pb-6 border-b border-gray-200 text-gray-800 text-left">
                        <div class="w-full flex mb-3 items-center">
                            <div class="flex-grow">
                                <span class="text-gray-600">Subtotal</span>
                            </div>
                            <div class="pl-3">
                                <span class="font-semibold">{formatMoney(checkoutItem.price)}</span>
                            </div>
                        </div>
                        <div class="w-full flex items-center">
                            <div class="flex-grow">
                                <span class="text-gray-600">Frete</span>
                            </div>
                            <div class="pl-3">
                                <span class="font-semibold">{formatMoney(deliveryFee)}</span>
                            </div>
                        </div>
                    </div>
                    <div class="mb-6 pb-6 border-b border-gray-200 md:border-none text-gray-800 text-xl">
                        <div class="w-full flex items-center text-left">
                            <div class="flex-grow">
                                <span class="text-gray-600">Total</span>
                            </div>
                            <div class="pl-3">
                                <span class="font-semibold text-gray-400 text-sm">BRL</span> <span class="font-semibold">{formatMoney(parseFloat(checkoutItem.price) + deliveryFee)}</span>
                            </div>
                        </div>

                        <p class="mt-8 text-lg font-medium text-left">Método de Envio</p>
                        <form class="mt-5 grid gap-6">
                            <div class="relative">
                                <input class="peer hidden" id="radio_1" type="radio" name="radio" checked />
                                <span class="peer-checked:border-gray-700 absolute right-4 top-1/2 box-content block h-3 w-3 -translate-y-1/2 rounded-full border-8 border-gray-300 bg-white"></span>
                                <label class="peer-checked:border-2 peer-checked:border-gray-700 peer-checked:bg-gray-50 flex cursor-pointer select-none rounded-lg border border-gray-300 p-4" for="radio_1">
                                    <img class="w-14 object-contain" src="https://i0.wp.com/multarte.com.br/wp-content/uploads/2020/10/correios-pac-sedex-logo.png?resize=696%2C366&ssl=1" alt="" />
                                    <div class="ml-5 text-left">
                                        <span class="mt-2 font-semibold">PAC / SEDEX</span>
                                        <p class="text-slate-500 text-sm leading-6">Entrega em 10 - 15 dias</p>
                                    </div>
                                </label>
                            </div>
                        </form>
                    </div>
                </div>
                <div class="px-3 md:w-5/12">
                    <div class="w-full mx-auto rounded-lg bg-white border border-gray-200 text-gray-800 font-light mb-6">
                        <div class="w-full p-3 border-b border-gray-200">
                            <div class="mb-5">
                                <label for="type1" class="flex items-center cursor-pointer">
                                    <input
                                        type="radio"
                                        class="form-radio h-5 w-5 text-indigo-500"
                                        name="type"
                                        id="cartao_credito"
                                        onChange={(e) => handleOptionChange(e)}
                                        checked={selectedOption === "cartao_credito"}
                                    />
                                    <img src={visa_img} class="h-6 ml-3" />
                                    <img src={mastercard_img} class="h-6 ml-1" />
                                    <img src={elo_img} class="h-6 ml-1" />
                                    <img src={hiper_img} class="h-6 ml-1" />
                                    <img src={hipercard_img} class="h-6 ml-1" />
                                    <img src={amex_img} class="h-6 ml-1" />
                                </label>
                            </div>
                            {
                                (selectedOption === "cartao_credito") && (
                                    <div className='text-left'>
                                        <div class="mb-3">
                                            <label class="text-gray-600 font-semibold text-sm mb-2 ml-1">Nome</label>
                                            <div>
                                                <input
                                                    class="w-full px-3 py-2 mb-1 border border-gray-200 rounded-md focus:outline-none focus:border-indigo-500 transition-colors"
                                                    placeholder="John Smith"
                                                    type="text"
                                                    value={holder}
                                                    onChange={(e) => setHolder(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                        <div class="mb-3">
                                            <label class="text-gray-600 font-semibold text-sm mb-2 ml-1">Número do Cartão</label>
                                            <div>
                                                <IMaskInput
                                                    class="w-full px-3 py-2 mb-1 border border-gray-200 rounded-md focus:outline-none focus:border-indigo-500 transition-colors"
                                                    placeholder="0000 0000 0000 0000"
                                                    mask='0000 0000 0000 0000'
                                                    type="text"
                                                    value={numberCard}
                                                    onChange={(e) => setNumberCard(e.target.value)}
                                                />
                                            </div>
                                        </div>
                                        <div class="mb-3 -mx-2 flex items-end">
                                            <div class="px-2 w-2/4">
                                                <label class="text-gray-600 font-semibold text-sm mb-2 ml-1">Mês</label>
                                                <div>
                                                    <select
                                                        class="form-select w-full px-3 py-2 mb-1 border border-gray-200 rounded-md focus:outline-none focus:border-indigo-500 transition-colors cursor-pointer"
                                                        value={month}
                                                        onChange={(e) => setMonth(e.target.value)}
                                                    >
                                                        <option value="01">01 - Janeiro</option>
                                                        <option value="02">02 - Fevereiro</option>
                                                        <option value="03">03 - Março</option>
                                                        <option value="04">04 - Abil</option>
                                                        <option value="05">05 - Maio</option>
                                                        <option value="06">06 - Junho</option>
                                                        <option value="07">07 - Julho</option>
                                                        <option value="08">08 - Agosto</option>
                                                        <option value="09">09 - Setembro</option>
                                                        <option value="10">10 - Outubro</option>
                                                        <option value="11">11 - Novembro</option>
                                                        <option value="12">12 - Dezembro</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div class="px-2 w-1/4">
                                                <label class="text-gray-600 font-semibold text-sm mb-2 ml-1">Ano</label>
                                                <select
                                                    class="form-select w-full px-3 py-2 mb-1 border border-gray-200 rounded-md focus:outline-none focus:border-indigo-500 transition-colors cursor-pointer"
                                                    value={year}
                                                    onChange={(e) => setYear(e.target.value)}
                                                >
                                                    {
                                                        yearsValidadeCreditCard().map(year => <option value={year}>{year}</option>)
                                                    }
                                                </select>
                                            </div>
                                            <div class="px-2 w-1/4">
                                                <label class="text-gray-600 font-semibold text-sm mb-2 ml-1">CVC</label>
                                                <div>
                                                    <IMaskInput
                                                        class="w-full px-3 py-2 mb-1 border border-gray-200 rounded-md focus:outline-none focus:border-indigo-500 transition-colors"
                                                        mask="000"
                                                        placeholder="000"
                                                        type="text"
                                                        value={securityCode}
                                                        onChange={(e) => setSecurityCode(e.target.value)}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div class="mb-3">
                                            <label class="text-gray-600 font-semibold text-sm mb-2 ml-1">Número de Parcelas</label>
                                            <div>
                                                <select
                                                    class="form-select w-full px-3 py-2 mb-1 border border-gray-200 rounded-md focus:outline-none focus:border-indigo-500 transition-colors cursor-pointer"
                                                    value={installment}
                                                    onChange={(e) => setInstallment(e.target.value)}
                                                >
                                                    {
                                                        calcularParcelas().map((installment, index) => <option value={index + 1}>{index + 1}x de {formatMoney(installment)}</option>)
                                                    }
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                )
                            }
                        </div>
                        <div class="w-full p-3 border-b border-gray-200">
                            <div>
                                <label for="type1" class="flex items-center cursor-pointer">
                                    <input
                                        type="radio"
                                        class="form-radio h-5 w-5 text-indigo-500"
                                        name="type"
                                        id="boleto_bancario"
                                        onChange={(e) => handleOptionChange(e)}
                                        checked={selectedOption === "boleto_bancario"}
                                    />
                                    <img src={boleto_img} class="h-6 ml-3" />
                                    <span className='ml-3'>Boleto Bancário</span>
                                </label>
                            </div>
                        </div>
                        <div class="w-full p-3 border-b border-gray-200">
                            <div>
                                <label for="type1" class="flex items-center cursor-pointer">
                                    <input
                                        type="radio"
                                        class="form-radio h-5 w-5 text-indigo-500"
                                        name="type"
                                        id="pix"
                                        onChange={(e) => handleOptionChange(e)}
                                        checked={selectedOption === "pix"}
                                    />
                                    <img src={pix_img} class="h-6 ml-3" /> PIX
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="flex flex-wrap justify-between sm:col-span-6">
                {
                    (stepper !== 1) && (
                        <div onClick={() => setStepper(stepper - 1)} className='w-[200px] text-center bg-primary-red font-medium py-[10px] px-[20px] text-white duration-150 rounded-xl hover:cursor-pointer hover:bg-tertiary-red'>
                            <div className='flex items-center justify-between'>
                                <span>Voltar</span>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M9 15L3 9m0 0l6-6M3 9h12a6 6 0 010 12h-3" />
                                </svg>
                            </div>
                        </div>
                    )
                }
                <div onClick={(e) => payNow(e)} className='w-[200px] text-center bg-primary-blue font-medium py-[10px] px-[20px] text-white duration-150 rounded-xl hover:cursor-pointer hover:bg-tertiary-blue'>
                    {
                        (processing) ? (
                            <div className='flex items-center justify-between'>
                                <svg aria-hidden="true" role="status" class="inline mr-2 w-4 h-4 text-gray-200 animate-spin dark:text-gray-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"></path>
                                    <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="#1C64F2"></path>
                                </svg>
                                <span>PAGUE AGORA</span>
                            </div>
                        ) : (
                            <div className='flex items-center justify-between'>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M16.5 10.5V6.75a4.5 4.5 0 10-9 0v3.75m-.75 11.25h10.5a2.25 2.25 0 002.25-2.25v-6.75a2.25 2.25 0 00-2.25-2.25H6.75a2.25 2.25 0 00-2.25 2.25v6.75a2.25 2.25 0 002.25 2.25z" />
                                </svg>
                                <span>PAGUE AGORA</span>
                            </div>
                        )
                    }
                </div>
            </div>
        </>

    )
}