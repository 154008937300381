import React from 'react';
import { useContext } from 'react';
import WhiteLogo from '../assets/logo/WhiteLogo';
import { Link } from 'react-router-dom';
import PaymentContext from '../context/PaymentContext';

export default function Boleto() {
    const { boleto } = useContext(PaymentContext);

    const date = new Date();
    const year = date.getFullYear();

    return (
        <>
            <header className='w-full bg-primary-red h-[55px]'>
                <Link to="/">
                    <WhiteLogo className='h-full scale-125 hover:opacity-80 duration-150 mx-auto' />
                </Link>
            </header>
            <section className="bg-background-gray py-10">
                {/* =======@ DESKTOP @======= */}
                <article className='bg-white max-w-[862px] py-[30px] rounded-xl shadow-md mx-auto mb-[40px] px-[30px] hidden lg:block'>
                    <div className="flex flex-wrap justify-between sm:col-span-6 mt-[20px]">
                        <div className='w-[200px] text-center bg-primary-red font-medium py-[10px] px-[20px] text-white duration-150 rounded-xl hover:cursor-pointer hover:bg-tertiary-red'>
                            <div className='flex items-center justify-between'>
                                <a href={boleto.charge.links[0].href} target='__blank' download={'BOLETO-MERCADO-UNIDO.pdf'}>Baixar Boleto</a>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5M16.5 12L12 16.5m0 0L7.5 12m4.5 4.5V3" />
                                </svg>
                            </div>
                        </div>
                        <div className='w-[200px] text-center bg-primary-blue font-medium py-[10px] px-[20px] text-white duration-150 rounded-xl hover:cursor-pointer hover:bg-tertiary-blue'>
                            <div className='flex items-center justify-between'>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z" />
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                                </svg>
                                <a target='__blank' href={boleto.charge.links[0].href}>Visualizar Boleto</a>
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-row justify-center items-center">
                        <img src={boleto.charge.links[1].href} />
                    </div>
                </article>

                {/* =======@ MOBILE @======= */}
                <article className='bg-white py-[30px] rounded-xl shadow-md mb-[40px] px-[30px] mx-[16px] mb-[40px] lg:hidden'>
                    <div className="flex flex-wrap justify-between sm:col-span-6 mt-[20px]">
                        <div className='w-[200px] text-center bg-primary-red font-medium py-[10px] px-[20px] text-white duration-150 rounded-xl hover:cursor-pointer hover:bg-tertiary-red'>
                            <div className='flex items-center justify-between'>
                                <a href={boleto.charge.links[0].href} target='__blank' download={'BOLETO-MERCADO-UNIDO.pdf'}>Baixar Boleto</a>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5M16.5 12L12 16.5m0 0L7.5 12m4.5 4.5V3" />
                                </svg>
                            </div>
                        </div>
                        <div className='w-[200px] text-center bg-primary-blue font-medium py-[10px] px-[20px] text-white duration-150 rounded-xl hover:cursor-pointer hover:bg-tertiary-blue'>
                            <div className='flex items-center justify-between'>
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z" />
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                                </svg>
                                <a target='__blank' href={boleto.charge.links[0].href}>Visualizar Boleto</a>
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-row justify-center items-center">
                        <img src={boleto.charge.links[1].href} />
                    </div>
                </article>
            </section>
            <footer className='bg-background-gray text-[12px] text-center w-full mx-auto pb-[15px] text-dark-gray'>
                <hr className='divider2 mb-4 mx-8' />
                © {year} Mercado Unido. Todos os direitos reservados
            </footer>
        </>
    )
}