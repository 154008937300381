import React, { useContext } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import WhiteLogo from '../assets/logo/WhiteLogo'
import MainContext from '../context/MainContext';
import { formatMoney } from '../utils/format';
import logo_pagseguro from '../assets/images/logo-pagseguro.png';
import FinishedOrderStatusSection from '../components/small_components/FinishedOrderStatusSection';
import AuthContext from '../context/AuthContext';
import { useEffect } from 'react';

export default function FinishOrder () {
  const { selectedOffer, deliveryFee, selectedImage, selectedCart, getOffers } = useContext(MainContext);
  const { user, getItensCart} = useContext(AuthContext);
  const navigate = useNavigate();;

  const date = new Date();
  const year = date.getFullYear();

  useEffect(() => {
    async function updateCartAndOffer() {
      await getItensCart();
      await getOffers();
    }

    updateCartAndOffer();
  }, [])
  
  return (
    <div className='h-screen bg-background-gray'>
      <header className='w-full bg-primary-red h-[55px]'>
        <Link to="/">
          <WhiteLogo className='h-full scale-125 hover:opacity-80 duration-150 mx-auto' />
        </Link>
      </header>
      <section className="bg-background-gray w-full text-center pt-[20px]">

        { /* DESKTOP */}
        <article className='hidden lg:grid grid-cols-3 gap-[10px] max-w-[1280px] mx-auto px-[20px]'>
          <FinishedOrderStatusSection />
          <div className='row-span-1 col-span-3 bg-white rounded-xl shadow-md mx-auto w-full'>
            <div className='rounded-t-xl bg-background-gray border-[1px] h-[50px] w-full flex items-center'>
              <h2 className='text-primary-red text-left px-[39px] font-bold'>RESUMO DA COMPRA</h2>
            </div>
            <aside className='py-[30px] text-left px-[30px] pr-[120px] text-[14px] text-gray-700 flex flex-row items-center justify-between w-full'>
              <div className='flex flex-row items-center justify-between pb-[15px]'>
                <img className='max-w-[120px]' src={selectedImage[0].urlImg} alt="" />
                <p className='font-bold px-[25px]'>{selectedOffer.title}</p>
              </div>
              <div className='flex flex-col items-start justify-between pb-[15px]'>
                <p className='font-bold'>Prazo de entrega</p>
                <p className=''>15 dias</p>
              </div>
              <div className='flex flex-col items-start justify-between pb-[15px]'>
                <p className='font-bold'>Quantidade</p>
                <p className=''>{selectedCart.quantity}</p>
              </div>
              <div className='flex flex-col items-start justify-between pb-[15px]'>
                <p className='font-bold'>Valor</p>
                <p className=''>{formatMoney(selectedCart.price)}</p>
              </div>
            </aside>
          </div>
          <div className='row-span-1 bg-white rounded-xl shadow-md mx-auto w-full'>
            <div className='rounded-t-xl bg-background-gray border-[1px] h-[50px] w-full flex items-center'>
              <h2 className='text-primary-red text-left px-[39px] font-bold'>ENDEREÇO DE ENTREGA</h2>
            </div>
            <aside className='pt-[40px] pb-[50px] text-left pl-[30px] pr-[60px] text-[14px] text-gray-700'>
              <p className='font-medium'>
                {`${user.firstName} ${user.lastName}`}
              </p>
              <p>
                {
                  `${user.mainAddress.street}, ${user.mainAddress.number} - ${user.mainAddress.complement}`
                }
              </p>
              <p>
                {user.mainAddress.district}
              </p>
              <p>
                {`${user.mainAddress.city} - ${user.mainAddress.state}`}
              </p>
              <p>
                {`CEP ${user.mainAddress.cep}`}
              </p>
            </aside>
          </div>
          <div className='row-span-1 bg-white rounded-xl shadow-md mx-auto w-full'>
            <div className='rounded-t-xl bg-background-gray border-[1px] h-[50px] w-full flex items-center'>
              <h2 className='text-primary-red text-left px-[39px] font-bold'>FORMA DE PAGAMENTO</h2>
            </div>
            <aside className='flex items-center justify-center w-full h-[217px]'>
              <img className='p-[10px] w-[200px] border-[2px] border-green shadow-md rounded-xl' src={ logo_pagseguro } alt="Logo Mercado Pago" />
            </aside>
          </div>
          <div className='row-span-1 bg-white rounded-xl shadow-md mx-auto w-full'>
            <div className='rounded-t-xl bg-background-gray border-[1px] h-[50px] w-full flex items-center'>
              <h2 className='text-primary-red text-left px-[39px] font-bold'>RESUMO DO PEDIDO</h2>
            </div>
            <aside className='py-[30px] text-left text-[14px] text-gray-700 flex items-center justify-between w-full flex flex-col px-[30px]'>
              <div className='flex flex-row items-center justify-between pb-[15px] w-full'>
                <p className=''>Produto</p>
                <p className='font-medium'>{formatMoney(selectedCart.price)}</p>
              </div>
              <div className='flex flex-row items-center justify-between pb-[26px] w-full'>
                <p className=''>Frete</p>
                <p className='font-medium'>{formatMoney(deliveryFee)}</p>
              </div>
              <div className='flex flex-row items-start justify-between border-gray-700 border-t-[1px] pt-[28px] w-full'>
                <p className='text-[22px] font-medium'>Total</p>
                <div className='text-right'>
                  <p className='text-[22px] text-green font-medium'>{formatMoney(parseFloat(selectedCart.price) + parseFloat(deliveryFee))}</p>
                  <p className='text-[14px] -mt-[8px]'>Em até 10x sem juros</p>
                </div>
              </div>
            </aside>
          </div>
          <div className='row-span-1 col-span-3 mx-auto w-full'>
            <button onClick={() => navigate('/')} className='bg-primary-blue text-white font-medium px-[20px] py-[10px] rounded-xl my-[20px] hover:bg-tertiary-blue duration-150 hover:shadow-md'>
              Página inicial
            </button>
          </div>
        </article>

        { /* MOBILE */}
        <article className='grid gap-[12px] w-full mx-auto px-[20px] lg:hidden'>
          <FinishedOrderStatusSection />
          <div className='row-span-1 bg-white rounded-xl shadow-md mx-auto w-full'>
            <div className='rounded-t-xl bg-background-gray border-[1px] h-[50px] w-full flex items-center'>
              <h2 className='text-primary-red text-left px-[39px] font-bold'>RESUMO DA COMPRA</h2>
            </div>
            <aside className='py-[30px] text-left px-[58px] text-[14px] text-gray-700'>
              <div className='flex flex-col items-center justify-between pb-[15px]'>
                <p className='font-bold'>{selectedOffer.title}</p>
                <img className='p-[20px]' src={ selectedImage[0].urlImg } alt="" />
              </div>
              <div className='flex flex-col items-center justify-between pb-[15px]'>
                <p className='font-bold'>Prazo de entrega</p>
                <p className=''>15 dias</p>
              </div>
              <div className='flex flex-col items-center justify-between pb-[15px]'>
                <p className='font-bold'>Quantidade</p>
                <p className=''>{selectedCart.quantity}</p>
              </div>
              <div className='flex flex-col items-center justify-between pb-[15px]'>
                <p className='font-bold'>Valor</p>
                <p className=''>{formatMoney(selectedCart.price)}</p>
              </div>
            </aside>
          </div>
          <div className='row-span-1 bg-white rounded-xl shadow-md mx-auto w-full'>
            <div className='rounded-t-xl bg-background-gray border-[1px] h-[50px] w-full flex items-center'>
              <h2 className='text-primary-red text-left px-[39px] font-bold'>ENDEREÇO DE ENTREGA</h2>
            </div>
            <aside className='pt-[20px] pb-[50px] text-left pl-[40px] pr-[15px] text-[14px] text-gray-700'>
              <p className='font-medium'>
                {`${user.firstName} ${user.lastName}`}
              </p>
              <p>
                {
                  `${user.mainAddress.street}, ${user.mainAddress.number} - ${user.mainAddress.complement}`
                }
              </p>
              <p>
                {user.mainAddress.district}
              </p>
              <p>
                {`${user.mainAddress.city} - ${user.mainAddress.state}`}
              </p>
              <p>
                {`CEP ${user.mainAddress.cep}`}
              </p>
            </aside>
          </div>
          <div className='row-span-1 bg-white rounded-xl shadow-md mx-auto w-full'>
            <div className='rounded-t-xl bg-background-gray border-[1px] h-[50px] w-full flex items-center'>
              <h2 className='text-primary-red text-left px-[39px] font-bold'>FORMA DE PAGAMENTO</h2>
            </div>
            <aside className='py-[30px] text-left px-[20px] text-[14px] text-gray-700 flex items-center justify-center'>
              <img className='p-[10px] w-[200px] border-[2px] border-green shadow-md rounded-xl' src={ logo_pagseguro } alt="Logo Mercado Pago" />
            </aside>
          </div>
          <div className='row-span-1 bg-white rounded-xl shadow-md mx-auto w-full'>
            <div className='rounded-t-xl bg-background-gray border-[1px] h-[50px] w-full flex items-center'>
              <h2 className='text-primary-red text-left px-[39px] font-bold'>RESUMO DO PEDIDO</h2>
            </div>
            <aside className='py-[30px] text-left text-[14px] text-gray-700 flex items-center justify-between w-full flex flex-col px-[30px]'>
              <div className='flex flex-row items-center justify-between pb-[15px] w-full'>
                <p className=''>Produto</p>
                <p className='font-medium'>{formatMoney(selectedCart.price)}</p>
              </div>
              <div className='flex flex-row items-center justify-between pb-[26px] w-full'>
                <p className=''>Frete</p>
                <p className='font-medium'>{formatMoney(deliveryFee)}</p>
              </div>
              <div className='flex flex-row items-start justify-between border-gray-700 border-t-[1px] pt-[28px] w-full'>
                <p className='text-[22px] font-medium'>Total</p>
                <div className='text-right'>
                  <p className='text-[22px] text-green font-medium'>{formatMoney(parseFloat(selectedCart.price) + parseFloat(deliveryFee))}</p>
                  <p className='text-[14px] -mt-[8px]'>Em até 10x sem juros</p>
                </div>
              </div>
            </aside>
          </div>
          <div className='row-span-1 mx-auto w-full'>
            <button onClick={() => navigate('/')} className='bg-primary-blue text-white font-medium px-[20px] py-[10px] rounded-xl shadow-md my-[20px]'>
              Página inicial
            </button>
          </div>
        </article>
      </section>
      <footer className='bg-background-gray text-[12px] text-center w-full mx-auto pb-[15px] text-dark-gray'>
        <hr className='divider2 mb-4 mx-8'/>
        © {year} Mercado Unido. Todos os direitos reservados
      </footer>
    </div>
  )
} 