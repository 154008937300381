import axios from 'axios';

export const sandbox = 'https://sandbox.api.pagseguro.com';
export const TOKEN_PAGSEGURO = '5692EDEFE76F4165967B5C8DD288BC74';
export const url = 'https://api.buquemarque.com.br/api';

//axios.defaults.headers.common.Accept = 'application/json';

const api = axios.create({
  baseURL: url,
  withCredentials: true,
  headers: {
    'Accept': 'application/json',
  },
  transformRequest: [(data, headers) => {

    if (headers['Content-Type'] && headers['Content-Type'] === 'multipart/form-data') {
      const form = new FormData();

      for (const key in data) {
        const value = data[key];

        if (Array.isArray(value)) {
          const arrayKey = `${key}[]`;

          value.forEach(v => {
            form.append(arrayKey, v);
          });
        } else {
          form.append(key, value);
        }
      }

      return form;
    }

    return data;
  }, ...axios.defaults.transformRequest]
});

export default api;
