import React from 'react'

export default function Divider({ color }) {
  const dividerStyle = {
    backgroundColor: color,
    height: '0.5px',
    marginTop: '20px',
    marginBottom: '20px',
  }

  return (
    <hr style={ dividerStyle }/>
  )
}