import axios from 'axios';
import { url } from './api';

export const signIn = async (email, password) => {
    try {
        const response = await axios.post(url + '/login', {
            email: email,
            password: password
        })

        return (response.status === 200) ? response : false;
    } catch (error) {
        console.log(error);
        return false;
    }
}

export const signOut = async (token) => {
    try {
        const response = await axios.post(url + '/logout', {}, {
            headers: {
                'Authorization': `Bearer ${token}`,
            }
        });

        console.log(response);

        return (response.status === 200) ? response : false;
    } catch (error) {
        console.log(error);
        return false;
    }
}