import React, { useContext, useState } from 'react';
import { IMaskInput } from 'react-imask';
import { Link, useNavigate } from 'react-router-dom';
import MainContext from '../context/MainContext';
import AvatarChange from './small_components/AvatarChange';
import axios from 'axios';
import AuthContext from '../context/AuthContext';
import { Toast } from './../utils/toast';

export default function FormsMobile() {
  const navigate = useNavigate();
  const {
    createAccStep, setCreateAccStep,
    avatar, terms
  } = useContext(MainContext);

  const { signIn } = useContext(AuthContext);

  const [email, setEmail] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [birthday, setBirthday] = useState('');
  const [document, setDocument] = useState('');
  const [password, setPassword] = useState('');
  const [phone, setPhone] = useState('');
  const [cep, setCep] = useState('');
  const [street, setStreet] = useState('');
  const [number, setNumber] = useState('');
  const [complement, setComplement] = useState('');
  const [district, setDistrict] = useState('');
  const [city, setCity] = useState('');
  const [state, setState] = useState('');
  const [reference, setReference] = useState('');

  const [processing, setProcessing] = useState(false);

  const [selectedIds, setSelectedIds] = useState([]);

  const handleCheckBoxTermChange = (e) => {
    const id = e.target.value;
    const updatedIds = [...selectedIds];

    if (selectedIds.includes(id)) {
      // Remove o ID se já estiver presente
      const index = updatedIds.indexOf(id);
      updatedIds.splice(index, 1);
    } else {
      // Adicione o ID se não estiver presente
      updatedIds.push(id);
    }

    setSelectedIds(updatedIds);
  };

  async function hadleCreateAccount(e) {
    e.preventDefault();

    try {
      if (email !== '' && firstName !== '' && lastName !== '' && birthday !== '' && document !== '' || password !== '' && phone !== '' && cep !== '' && street !== '' && number !== '' && reference !== '' && district !== '' && city !== '' && state !== '' && complement !== '') {
        var dateParts = birthday.replace(/\//g, "-").split('-');
        var dataFormated = dateParts[2] + "-" + dateParts[1] + "-" + dateParts[0];

        setProcessing(true);

        const responseUser = await axios.post('http://localhost/api/user/create-account', {
          firstName: firstName,
          lastName: lastName,
          email: email,
          birthday: dataFormated,
          cpf: document.replace(/\D/g, ""),
          phone: phone.replace(/\D/g, ""),
          password: password,
          image: (avatar !== null || avatar === '') ? avatar : undefined,
        }, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        });


        if (responseUser.status === 201) {
          const responseAddress = await axios.post('http://localhost/api/user/adresses', {
            street: street,
            district: district,
            number: number,
            cep: cep.replace(/\D/g, ""),
            complement: (complement !== '' && complement !== null) ? complement : undefined,
            reference: (reference !== '' && reference !== null) ? reference : undefined,
            city: city,
            state: state,
            main: true,
            id_user: responseUser.data.data.id
          })

          if (responseAddress.status === 201) {
            const response = await axios.post('http://localhost/api/terms/user', {
              idTerms: selectedIds,
              idUser: responseUser.data.data.id
            })

            await signIn(email, password);
            navigate('/');
          } else {
            Toast.fire({
              icon: 'info',
              title: 'CONTA CRIADA S/ENDEREÇO'
            })
            window.scrollTo(0, 0)
          }
        } else {
          Toast.fire({
            icon: 'error',
            title: 'SUA CONTA NÃO FOI CRIADA.',
          })
          window.scrollTo(0, 0)
        }
      } else {
        Toast.fire({
          icon: 'info',
          title: 'PREENCHA OS CAMPOS NECESSÁRIOS',
        })
        window.scrollTo(0, 0)
      }
    } catch (error) {
      Toast.fire({
        icon: 'error',
        title: 'SUA CONTA NÃO FOI CRIADA.',
      })
      window.scrollTo(0, 0)
    } finally {
      setCreateAccStep(1);
      setProcessing(false);
    }
  }

  async function fetchDataViaZipCode(e) {
    e.preventDefault();

    setDistrict("");
    setCity("");
    setStreet("");
    setState("");

    if (cep !== '') {
      axios.get(`http://viacep.com.br/ws/${cep.replace(/[.-]/g, "")}/json/`)
        .then((res) => {
          setDistrict(res.data.bairro);
          setCity(res.data.localidade);
          setStreet(res.data.logradouro);
          setState(res.data.uf);

          if (res.data.hasOwnProperty('erro')) {
            Toast.fire({
              icon: 'error',
              title: 'NÃO FOI POSSÍVEL ENCONTRAR O CEP.',
            })
          } else {
            Toast.fire({
              icon: 'success',
              title: 'CEP ENCONTRADO.',
            })
          }
        })
        .catch((err) => {
          Toast.fire({
            icon: 'error',
            title: 'NÃO FOI POSSÍVEL ENCONTRAR O CEP.',
          })
        })
    } else {
      Toast.fire({
        icon: 'info',
        title: 'INFORME O CEP PARA BUSCAR.',
      })
    }
  }

  return (
    <article className='bg-white py-[13px] rounded-xl shadow-md mx-[16px] mb-[40px] lg:hidden'>
      <section className='flex flex-row items-center justify-between pt-[52px] px-[54px]'>
        <h3 className='px-2 text-primary-blue font-bold text-[22px] mx-auto'>Dados Pessoais</h3>
      </section>
      <div className="flex-auto pb-[30px] pt-[40px] px-[20px]">
        <div className="mt-6 grid grid-cols-1 gap-y-[16px] gap-x-4 sm:grid-cols-4">
          {/* FORMS */}

          <div>
            <label className="block text-[16px] text-left font-medium text-primary-blue">
              E-mail
            </label>
            <div className="mt-1">
              <input
                onChange={(e) => setEmail(e.target.value)}
                value={email}
                type="text"
                className="block w-full rounded-md border-gray-300 shadow-sm focus:border-tertiary-blue focus:ring-tertiary-blue sm:text-[16px]"
              />
            </div>
          </div>
          <div>
            <label className="block text-[16px] text-left font-medium text-primary-blue">
              Nome
            </label>
            <div className="mt-1">
              <input
                onChange={(e) => setFirstName(e.target.value)}
                value={firstName}
                type="text"
                className="block w-full rounded-md border-gray-300 shadow-sm focus:border-tertiary-blue focus:ring-tertiary-blue sm:text-[16px]"
              />
            </div>
          </div>
          <div>
            <label className="block text-[16px] text-left font-medium text-primary-blue">
              Sobrenome
            </label>
            <div className="mt-1">
              <input
                onChange={(e) => setLastName(e.target.value)}
                value={lastName}
                type="text"
                className="block w-full rounded-md border-gray-300 shadow-sm focus:border-tertiary-blue focus:ring-tertiary-blue sm:text-[16px]"
              />
            </div>
          </div>
          <div>
            <label className="block text-[16px] text-left font-medium text-primary-blue">
              Data de nascimento
            </label>
            <div className="mt-1">
              <IMaskInput
                onChange={(e) => setBirthday(e.target.value)}
                value={birthday}
                mask='00/00/0000'
                type="text"
                className="block w-full rounded-md border-gray-300 shadow-sm focus:border-tertiary-blue focus:ring-tertiary-blue sm:text-[16px]"
              />
            </div>
          </div>
          <div>
            <label className="block text-[16px] text-left font-medium text-primary-blue">
              CPF
            </label>
            <div className="mt-1">
              <IMaskInput
                onChange={(e) => setDocument(e.target.value)}
                value={document}
                mask='000.000.000-00'
                type="text"
                className="block w-full rounded-md border-gray-300 shadow-sm focus:border-tertiary-blue focus:ring-tertiary-blue sm:text-[16px]"
              />
            </div>
          </div>
          <div>
            <label className="block text-[16px] text-left font-medium text-primary-blue">
              Senha (mínimo de 6 caracteres)
            </label>
            <div className="mt-1">
              <input
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className="block w-full rounded-md border-gray-300 shadow-sm focus:border-tertiary-blue focus:ring-tertiary-blue sm:text-[16px]"
              />
            </div>
          </div>
          <div className='mb-[30px]'>
            <AvatarChange />
          </div>
          <section className='flex flex-row items-center justify-between py-[50px] px-[24px] border-t'>
            <h3 className='px-2 text-primary-blue font-bold text-[22px] mx-auto'>Endereço de entrega</h3>
          </section>
          <div className="text-left">
            <label className="block text-[16px] text-left font-medium text-primary-blue">
              CEP
            </label>
            <div className="mt-1 flex rounded-md shadow-sm mb-[20px]">
              <div className="relative flex flex-grow items-stretch focus-within:z-10">
                <IMaskInput
                  onChange={(e) => setCep(e.target.value)}
                  value={cep}
                  mask='00.000-000'
                  type="text"
                  className="block w-full rounded-l-md border-gray-300 shadow-sm focus:border-tertiary-blue focus:ring-tertiary-blue sm:text-[16px]"
                />
              </div>
              <button
                type="button"
                className="relative -ml-px inline-flex items-center space-x-2 rounded-r-md border border-primary-blue bg-primary-blue px-4 py-2 text-sm font-medium text-white hover:opacity-80"
                onClick={(e) => fetchDataViaZipCode(e)}
              >
                OK
              </button>
            </div>
            <a className='text-[16px] text-gray-400 underline' target='_blank' href="https://buscacepinter.correios.com.br/app/endereco/index.php" rel="noreferrer">Não sei meu CEP</a>
          </div>
          <div>
            <label className="block text-[16px] text-left font-medium text-primary-blue">
              Endereço
            </label>
            <div className="mt-1">
              <input
                onChange={(e) => setStreet(e.target.value)}
                value={street}
                type="text"
                className="block w-full rounded-md border-gray-300 shadow-sm focus:border-tertiary-blue focus:ring-tertiary-blue sm:text-[16px]"
              />
            </div>
          </div>
          <div>
            <label className="block text-[16px] text-left font-medium text-primary-blue">
              Número
            </label>
            <div className="mt-1">
              <input
                onChange={(e) => setNumber(e.target.value)}
                value={number}
                type="text"
                className="block w-full rounded-md border-gray-300 shadow-sm focus:border-tertiary-blue focus:ring-tertiary-blue sm:text-[16px]"
              />
            </div>
          </div>
          <div>
            <label className="block text-[16px] text-left font-medium text-primary-blue">
              Complemento
            </label>
            <div className="mt-1">
              <input
                onChange={(e) => setComplement(e.target.value)}
                value={complement}
                type="text"
                className="block w-full rounded-md border-gray-300 shadow-sm focus:border-tertiary-blue focus:ring-tertiary-blue sm:text-[16px]"
              />
            </div>
          </div>
          <div>
            <label className="block text-[16px] text-left font-medium text-primary-blue">
              Bairro
            </label>
            <div className="mt-1">
              <input
                onChange={(e) => setDistrict(e.target.value)}
                value={district}
                type="text"
                className="block w-full rounded-md border-gray-300 shadow-sm focus:border-tertiary-blue focus:ring-tertiary-blue sm:text-[16px]"
              />
            </div>
          </div>
          <div>
            <label className="block text-[16px] text-left font-medium text-primary-blue">
              Cidade
            </label>
            <div className="mt-1">
              <input
                onChange={(e) => setCity(e.target.value)}
                value={city}
                type="text"
                className="block w-full rounded-md border-gray-300 shadow-sm focus:border-tertiary-blue focus:ring-tertiary-blue sm:text-[16px]"
              />
            </div>
          </div>
          <div>
            <label className="block text-[16px] text-left font-medium text-primary-blue">
              Estado
            </label>
            <div className="mt-1">
              <input
                onChange={(e) => setState(e.target.value)}
                value={state}
                type="text"
                className="block w-full rounded-md border-gray-300 shadow-sm focus:border-tertiary-blue focus:ring-tertiary-blue sm:text-[16px]"
              />
            </div>
          </div>
          <div>
            <label className="block text-[16px] text-left font-medium text-primary-blue">
              Ponto de referência
            </label>
            <div className="mt-1">
              <input
                onChange={(e) => setReference(e.target.value)}
                value={reference}
                type="text"
                className="block w-full rounded-md border-gray-300 shadow-sm focus:border-tertiary-blue focus:ring-tertiary-blue sm:text-[16px]"
              />
            </div>
          </div>
          <div>
            <label className="block text-[16px] text-left font-medium text-primary-blue">
              Telefone Celular
            </label>
            <div className="mt-1">
              <IMaskInput
                onChange={(e) => setPhone(e.target.value)}
                value={phone}
                mask='(00) 00000-0000'
                type="text"
                className="block w-full rounded-md border-gray-300 shadow-sm focus:border-tertiary-blue focus:ring-tertiary-blue sm:text-[16px]"
              />
            </div>
          </div>
          <div className='pt-[60px] text-left'>
            <h3 className='text-primary-blue text-[22px]' ><strong>Notificações</strong> (opcional)</h3>
            <p className='text-primary-blue text-[14px] py-[20px]'>
              O contato com você é muito importante pra gente fazer a diferença no seu dia a dia.
            </p>
            {
              terms.map(term => {
                return (
                  <div className={`relative flex items-start ${(term.name === "app_mercado_unido_privacy_policy") && 'mt-[18px]'}`}>
                    <div className="flex h-5 items-center">
                      <input
                        type="checkbox"
                        id={term.name}
                        value={term.id}
                        onChange={(e) => handleCheckBoxTermChange(e)}
                        checked={selectedIds.includes(term.id)}
                        className="h-4 w-4 rounded border-gray-700 text-primary-red focus:ring-0"
                      />
                    </div>
                    <div className={`ml-3 text-sm mb-[18px]`}>
                      <label htmlFor={term.name} className="text-[14px] text-gray-700 hover:cursor-pointer ">
                        {term.description}
                      </label>
                    </div>
                  </div>
                )
              })
            }
          </div>
          <div className='pt-[60px] text-left'>
            <h3 className='text-primary-blue text-[22px] font-bold pb-[22px]' >Endereço de cobrança</h3>
            <div className="relative flex items-start">
              <div className="flex h-5 items-center">
                <input
                  type="checkbox"
                  id='same-address'
                  className="h-4 w-4 rounded border-gray-700 text-primary-red focus:ring-0"
                />
              </div>
              <div className="ml-3 text-sm">
                <label htmlFor="same-address" className="text-[16px] text-gray-700 hover:cursor-pointer">
                  Usar o mesmo endereço
                </label>
              </div>
            </div>
          </div>

          {/* BOTÕES */}
          <div className="flex flex-row items-center justify-center mt-[80px]">
            <button onClick={() => navigate(-1)} className="mx-1 font-bold text-[16px] w-full border-2 border-primary-blue bg-white rounded-xl px-4 py-[18px] text-primary-blue duration-150 content-start">
              Cancelar
            </button>
            <button onClick={(e) => hadleCreateAccount(e)} className="mx-1 font-bold text-[16px] w-full border border-white bg-primary-blue rounded-xl px-4 py-[18px] text-white hover:bg-tertiary-blue hover:border-tertiary-blue duration-150 content-start">
              {
                processing && (
                  <svg aria-hidden="true" role="status" class="inline mr-2 w-4 h-4 text-gray-200 animate-spin dark:text-gray-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor"></path>
                    <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="#1C64F2"></path>
                  </svg>
                )
              }
              Criar
            </button>
          </div>
        </div>
      </div>
    </article>
  )
}