import React, { useContext, useEffect, useState } from 'react';
import AuthContext from '../../context/AuthContext';
import { IMaskInput } from 'react-imask';
import { formatDatePtBr } from './../../utils/format'
import PaymentContext from '../../context/PaymentContext';

export default function DadosPessoaisFields() {
    const { user, setUser, toAlterPersonalData, setToAlterPersonalData } = useContext(AuthContext);

    return (
        <>
            <div className="sm:col-span-3">
                <label className="block text-sm font-bold text-[16px] text-primary-blue text-left">
                    Nome
                </label>
                <div className="mt-1">
                    <input className="block w-full rounded-[11px] border-gray-300 shadow-sm sm:text-sm py-1 px-2 focus:border-primary-blue focus:outline-none focus:ring-1 focus:ring-primary-blue pr-[11px] pl-[11px] pb-[11px] pt-[11px]"
                        type="text"
                        value={user.firstName}
                        onChange={(e) => setUser({
                            id: user.id,
                            firstName: e.target.value,
                            lastName: user.lastName,
                            email: user.email,
                            birthday: user.birthday,
                            cpf: user.cpf,
                            phone: user.phone,
                            profilePictureUrl: user.profilePictureUrl,
                            adresses: user.adresses,
                            mainAddress: user.mainAddress
                        })}
                        maxLength="255"
                    />
                </div>
            </div>

            <div className="sm:col-span-3">
                <label className="block text-sm font-bold text-[16px] text-primary-blue text-left">
                    Sobrenome
                </label>
                <div className="mt-1">
                    <input className="block w-full rounded-[11px] border-gray-300 shadow-sm sm:text-sm py-1 px-2 focus:border-primary-blue focus:outline-none focus:ring-1 focus:ring-primary-blue pr-[11px] pl-[11px] pb-[11px] pt-[11px]"
                        type="text"
                        value={user.lastName}
                        onChange={(e) => setUser({
                            id: user.id,
                            firstName: user.firstName,
                            lastName: e.target.value,
                            email: user.email,
                            birthday: user.birthday,
                            cpf: user.cpf,
                            phone: user.phone,
                            profilePictureUrl: user.profilePictureUrl,
                            adresses: user.adresses,
                            mainAddress: user.mainAddress
                        })}
                        maxLength="255"
                    />
                </div>
            </div>

            <div className="sm:col-span-6">
                <label className="block text-sm font-bold text-[16px] text-primary-blue text-left">
                    Email
                </label>
                <div className="mt-1">
                    <input className="block w-full rounded-[11px] border-gray-300 shadow-sm sm:text-sm py-1 px-2 focus:border-primary-blue focus:outline-none focus:ring-1 focus:ring-primary-blue pr-[11px] pl-[11px] pb-[11px] pt-[11px]"
                        type="text"
                        value={user.email}
                        onChange={(e) => setUser({
                            id: user.id,
                            firstName: user.firstName,
                            lastName: user.lastName,
                            email: e.target.value,
                            birthday: user.birthday,
                            cpf: user.cpf,
                            phone: user.phone,
                            profilePictureUrl: user.profilePictureUrl,
                            adresses: user.adresses,
                            mainAddress: user.mainAddress
                        })}
                        maxLength="255"
                    />
                </div>
            </div>

            <div className="sm:col-span-6">
                <label className="block text-sm font-bold text-[16px] text-primary-blue text-left">
                    CPF
                </label>
                <div className="mt-1">
                    <IMaskInput
                        value={user.cpf}
                        mask='000.000.000-00'
                        type="text"
                        onChange={(e) => setUser({
                            id: user.id,
                            firstName: user.firstName,
                            lastName: user.lastName,
                            email: user.email,
                            birthday: user.birthday,
                            cpf: e.target.value,
                            phone: user.phone,
                            profilePictureUrl: user.profilePictureUrl,
                            adresses: user.adresses,
                            mainAddress: user.mainAddress
                        })}
                        className="block w-full rounded-[11px] border-gray-300 shadow-sm sm:text-sm py-1 px-2 focus:border-primary-blue focus:outline-none focus:ring-1 focus:ring-primary-blue pr-[11px] pl-[11px] pb-[11px] pt-[11px]"
                    />
                </div>
            </div>

            <div className="sm:col-span-6">
                <label className="block text-sm font-bold text-[16px] text-primary-blue text-left">
                    Data de Nascimento
                </label>
                <div className="mt-1">
                    <IMaskInput
                        value={formatDatePtBr(user.birthday)}
                        mask='00/00/0000'
                        type="text"
                        onChange={(e) => setUser({
                            id: user.id,
                            firstName: user.firstName,
                            lastName: user.lastName,
                            email: user.email,
                            birthday: e.target.value,
                            cpf: user.cpf,
                            phone: user.phone,
                            profilePictureUrl: user.profilePictureUrl,
                            adresses: user.adresses,
                            mainAddress: user.mainAddress
                        })}
                        className="block w-full rounded-[11px] border-gray-300 shadow-sm sm:text-sm py-1 px-2 focus:border-primary-blue focus:outline-none focus:ring-1 focus:ring-primary-blue pr-[11px] pl-[11px] pb-[11px] pt-[11px]"
                    />
                </div>
            </div>

            <div className="sm:col-span-3">
                <label className="block text-sm font-bold text-[16px] text-primary-blue text-left">
                    Telefone
                </label>
                <div className="mt-1">
                    <IMaskInput
                        value={user.phone}
                        mask='(00) 00000-0000'
                        type="text"
                        onChange={(e) => setUser({
                            id: user.id,
                            firstName: user.firstName,
                            lastName: user.lastName,
                            email: user.email,
                            birthday: user.birthday,
                            cpf: user.cpf,
                            phone: e.target.value,
                            profilePictureUrl: user.profilePictureUrl,
                            adresses: user.adresses,
                            mainAddress: user.mainAddress
                        })}
                        className="block w-full rounded-[11px] border-gray-300 shadow-sm sm:text-sm py-1 px-2 focus:border-primary-blue focus:outline-none focus:ring-1 focus:ring-primary-blue pr-[11px] pl-[11px] pb-[11px] pt-[11px]"
                    />
                </div>
            </div>
        </>
    )
}