import { useEffect } from 'react';
import Routing from './Routes';
import { AddressProvider } from './context/AddressProvider';
import { AuthProvider } from './context/AuthProvider';
import MainProvider from './context/MainProvider';
import PaymentProvider from './context/PaymentProvider';
import api from './services/api';

function App() {
  useEffect(() => {
    
  }, []);

  return (
    <AuthProvider>
      <AddressProvider>
        <PaymentProvider>
          <MainProvider>
            <Routing />
          </MainProvider>
        </PaymentProvider>
      </AddressProvider>
    </AuthProvider>
  );
}

export default App;
