import moment from 'moment-timezone';
import 'moment/locale/pt-br';
const { DateTime } = require('luxon');

function formatAddress(param) {
  const { address, city, uf } = param;
  const completeAddress = `${address}. ${city}/${uf}`;

  return completeAddress;
};

function getCurrentTimestamp() {
  const currentTimestamp = Date.now();
  const dateObject = new Date(currentTimestamp);

  return dateObject.toLocaleString();
}

function formatPhoneNumber(phoneNumber) {
  const cleaned = ('' + phoneNumber).replace(/\D/g, '');
  const match = cleaned.match(/^(\d{2})(\d{2})(\d{5})(\d{4})$/);
  if (match) {
    return '+' + match[1] + ' (' + match[2] + ') ' + match[3] + '-' + match[4];
  }
  return cleaned;
};

function convertDataForPgSql(data) {
  // Divide a data em dia, mês e ano
  const partes = data.split('/');
  const dia = partes[0];
  const mes = partes[1];
  const ano = partes[2];

  // Formata a data no formato "yyyy-mm-dd"
  const dataFormatada = `${ano}-${mes}-${dia}`;
  return dataFormatada;
}

function formatMoney(value) {
  const result = Number(value)
  return new Intl.NumberFormat("pt-br", { style: "currency", "currency": "BRL" }).format(result);
};

function formatDate(date) {
  const newDate = new Date(date)
  const format = `${newDate.toLocaleDateString()} às ${newDate.getHours()}:${newDate.getMinutes()}h`
  return format;
};

function formatCPF(cpf) {
  const cleanCPF = cpf.replace(/\D/g, '');
  const formattedCPF = cleanCPF.replace(
    /(\d{3})(\d{3})(\d{3})(\d{2})/,
    '$1.$2.$3-$4'
  );

  return formattedCPF;
}

function formatDatePtBr(inputDate) {
  const parts = inputDate.split('-');
  const day = parts[2];
  const month = parts[1];
  const year = parts[0];

  const formattedDate = `${day}-${month}-${year}`;

  return formattedDate;
}

function TimestampFormatterCreatedAt(timestamp) {
  const formatoDesejado = 'DD/MMM HH:mm'; // DD: dia, MMM: abreviação do mês, HH: hora, mm: minuto

  // Configurar a localização para pt-br
  moment.locale('pt-br');

  const momentoUtc = moment.utc(timestamp, 'YYYY-MM-DD HH:mm:ss');
  const momentoBrasilia = momentoUtc.tz('America/Sao_Paulo');

  const resultado = momentoBrasilia.format(formatoDesejado);

  return resultado;
}

function TimestampFormatterPaymenteDate(timestamp) {
  const formatoDesejado = 'DD/MMM HH:mm'; // DD: dia, MMM: abreviação do mês, HH: hora, mm: minuto

  // Configurar a localização para pt-br
  moment.locale('pt-br');

  const momentoUtc = moment.utc(timestamp, 'YYYY-MM-DD HH:mm:ss');
  const momentoBrasilia = momentoUtc;

  const resultado = momentoBrasilia.format(formatoDesejado);

  return resultado;
}

function formatFistPurchaseForTimer(firstPurchase) {
  const dateTime = DateTime.fromSQL(firstPurchase, { zone: 'utc' });
  const dateTimeBrasilia = dateTime.setZone('America/Sao_Paulo');
  return dateTimeBrasilia.toFormat('yyyy-MM-dd HH:mm:ss');
}

function formatBrDate(date) {
  const options = {
    timeZone: "America/Sao_Paulo",
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
  };

  return date.toLocaleDateString("pt-BR", options);
}

function addBusinessDays(startDate, days) {
  const dayMs = 24 * 60 * 60 * 1000;

  for (let i = 0; i < days; i++) {
    startDate.setTime(startDate.getTime() + dayMs);

    while (startDate.getDay() === 0 || startDate.getDay() === 6) {
      startDate.setTime(startDate.getTime() + dayMs);
    }
  }

  return startDate;
}

function isOfferExpiration(firstPurchaseDate, days, hours) {
  const brTimeZoneOffset = -3; // UTC offset para Brasília é -3 horas

  const firstPurchaseDateObject = new Date(firstPurchaseDate);
  const brFirstPurchaseDate = new Date(firstPurchaseDateObject.getTime() + brTimeZoneOffset * 60 * 60 * 1000);

  const currentDate = new Date();

  const [hoursToAdd, minutesToAdd, secondsToAdd] = hours.split(":");

  const expirationDate = new Date(brFirstPurchaseDate);
  expirationDate.setDate(expirationDate.getDate() + days);
  expirationDate.setHours(expirationDate.getHours() + parseInt(hoursToAdd, 10));
  expirationDate.setMinutes(expirationDate.getMinutes() + parseInt(minutesToAdd, 10));
  expirationDate.setSeconds(expirationDate.getSeconds() + parseInt(secondsToAdd, 10));

  if (expirationDate <= currentDate) {
    return {
      hasExpired: true,
      expirationDate: formatBrDate(expirationDate),
    };
  } else {
    return {
      hasExpired: false,
      expirationDate: formatBrDate(expirationDate),
    };
  }
}

function getCurrentDate() {
  const dataAtual = new Date();

  const ano = dataAtual.getFullYear(); // Obtém o ano com 4 dígitos
  const mes = String(dataAtual.getMonth() + 1).padStart(2, '0'); // Obtém o mês com 2 dígitos
  const dia = String(dataAtual.getDate()).padStart(2, '0'); // Obtém o dia com 2 dígitos

  return `${ano}-${mes}-${dia}`;
}

function addDays(data, days) {
  const dataOriginal = new Date(data);
  const dataNova = new Date(dataOriginal);
  dataNova.setDate(dataOriginal.getDate() + days);
  return dataNova.toISOString().split('T')[0];
}

function converterData(data) {
  // Divida a string da data nos componentes ano, mês e dia
  const partesData = data.split('-');
  
  if (partesData.length !== 3) {
    // Verifique se a data está no formato correto
    return 'Data inválida';
  }
  
  // Extraia o dia e o mês
  const dia = partesData[2];
  const mes = partesData[1];
  
  // Formate a data como "dd/mm"
  const dataFormatada = `${dia}/${mes}`;
  
  return dataFormatada;
}

export {
  formatAddress,
  formatPhoneNumber,
  formatMoney,
  formatDate,
  formatCPF,
  formatDatePtBr,
  convertDataForPgSql,
  TimestampFormatterCreatedAt,
  TimestampFormatterPaymenteDate,
  formatFistPurchaseForTimer,
  getCurrentTimestamp,
  isOfferExpiration,
  addBusinessDays,
  getCurrentDate,
  addDays,
  converterData,
};