import { useContext, useEffect, useState } from 'react'
// import { Disclosure } from '@headlessui/react'
import { SearchIcon } from '@heroicons/react/solid'
import { MenuIcon } from '@heroicons/react/outline'
// import WhiteLogo from '../assets/logo/logo branco.png';
import WhiteLogo from '../assets/logo/WhiteLogo';
import { MdArrowDropDown } from "react-icons/md";
import MainContext from '../context/MainContext';
import DepartmentMenu from './DepartmentMenu';
import { Link, useNavigate } from 'react-router-dom';
import ProfileLoggedOff from './small_components/ProfileLoggedOff';
import ProfileLoggedOffMobile from './small_components/ProfileLoggedOffMob';
import ProfileLoggedIn from './small_components/ProfileLoggedIn';
import ProfileLoggedInMob from './small_components/ProfileLoggedInMob';
import AuthContext from '../context/AuthContext';

export default function Header() {
  const {
    menu,
    setMenu,
    profileMenu,
    setProfileMenu,
    setOfferSearch,
  } = useContext(MainContext);

  const { isLogged } = useContext(AuthContext);

  const [query, setQuery] = useState('');

  const navigate = useNavigate();

  useEffect(() => {
    if (profileMenu) {
      setProfileMenu(!profileMenu)
    }

    if (menu) {
      document.body.classList.add('overflow-y-hidden');
    } else {
      document.body.classList.remove('overflow-y-hidden');
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [menu]);

  function handleSearch () {
    setOfferSearch(query);
    navigate(`/search`);
    setQuery('');
  }

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleSearch();
    }
  };

  return ( 
      <header className="bg-primary-red z-40 lg:h-[68px]">
        {/* DESKTOP */}
        <div className="hidden lg:flex items-center justify-between w-full max-w-[1920px] mx-auto pl-[80px] pr-[60px] h-full">
          <div className="flex items-center justify-between">
            <Link to="/">
              <WhiteLogo className='h-full hover:scale-105 duration-150' />
            </Link>
            <button onClick={() => setMenu(!menu)} className="px-2 ml-8 rounded-md py-1 flex flex-row items-center text-white hover:bg-tertiary-red duration-150 truncate">
              <MenuIcon className="block h-6 w-6 mx-2 text-white hover:scale-110 duration-150" aria-hidden="true" />
              Escolha o departamento
              <MdArrowDropDown className="mx-1" />
            </button>
          </div>
          <div className="py-2 text-sm flex-auto px-4">
            <div className="w-full">
              <label htmlFor="search-desktop" className="sr-only">
                Procure uma oferta
              </label>
              <div className="relative">
                <input
                  value={ query }
                  id="search-desktop"
                  name="search-desktop"
                  className="block w-full h-[40px] mx-auto pr-10 pl-3 py-1 border border-transparent rounded-lg leading-5 bg-white text-gray-300 placeholder-gray-600 focus:outline-none focus:bg-white focus:border-white focus:ring-secondary-red focus:text-gray-900 text-sm"
                  placeholder="Procure um produto ou oferta"
                  type="search"
                  onChange={(e) => setQuery(e.target.value)}
                  onKeyDown={handleKeyDown}
                />
                <div className="absolute inset-y-0 right-0 pr-3 flex items-center" onClick={() => handleSearch()}>
                  <SearchIcon className="h-5 w-5 text-primary-red" aria-hidden="true" />
                </div>
              </div>
            </div>
          </div>

          {
            isLogged ? (
              <ProfileLoggedIn />
            ) : (              
              <ProfileLoggedOff />
            )
          }

        </div>

        {/* MOBILE */}
        <div className="mx-auto px-4 sm:px-4 lg:hidden">
          <div className="relative flex items-center justify-between h-16">
            <div className="flex items-center lg:px-0">
              <button className="pr-2">
                <MenuIcon className="block h-6 w-6 text-white hover:scale-110" aria-hidden="true" onClick={() => setMenu(!menu)} />
              </button>
              <div className="flex-shrink-0 text-white font-bold">
                <Link to="/">
                  <WhiteLogo className='scale-90' />
                </Link>
              </div>
            </div>

            {
              isLogged ? (
                <ProfileLoggedInMob />
              ) : (
                <Link to='/login'>
                  <ProfileLoggedOffMobile />
                </Link>             
              )
            }
          </div>

          {/* SEARCHBAR */}
          <div className="flex-1 flex justify-center pb-4 lg:ml-6 lg:justify-end">
              <div className="w-full">
                <div className="relative">
                  <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none" onClick={() => handleSearch()}>
                    <SearchIcon className="h-5 w-5 text-primary-red" aria-hidden="true" />
                  </div>
                  <input
                    id="search-mobile"
                    name="search-mobile"
                    onChange={(e) => setQuery(e.target.value)}
                    className="block w-full pl-10 pr-3 py-2 border border-transparent rounded-md leading-5 bg-white text-gray-300 placeholder-gray-400 focus:outline-none focus:bg-white focus:border-white focus:ring-white focus:text-gray-900 sm:text-sm"
                    placeholder="Procure um produto"
                    type="search"
                  />
                </div>
              </div>
            </div>
        </div>
        
        {/* <DepartmentMenu /> */}        {
          menu === true ? (<DepartmentMenu />) : null
        }
      </header>
  )
}
