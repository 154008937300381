import React from 'react';
import Footer from '../components/Footer';
import Header from '../components/Header';
import { AiOutlineArrowLeft } from "react-icons/ai";
import { useNavigate } from 'react-router-dom';

function PrivacyPolicies() {
  const navigate = useNavigate();

  return (
    <>
      <Header />
      <section className="bg-background-gray py-10">
        <article className="bg-white mx-auto rounded-md w-5/6 shadow-md px-8 py-12 lg:pb-[60px] lg:pt-[38px] lg:px-[90px] max-w-screen-lg">
          <div className='flex flex-row items-center hover:cursor-pointer hover:opacity-80' onClick={() => navigate(-1)}>
            <AiOutlineArrowLeft className='text-primary-blue font-bold' />
            <p className='px-2 text-primary-blue underline'>Voltar</p>
          </div>
          <h3 className="text-primary-blue text-left w-full font-bold text-[35px]">Políticas de Privacidade</h3>
          <p className="pt-[30px]">
            <p>O Mercado Unido (aqui referido como "nós", "nos" ou "nosso") está empenhado em proteger a privacidade dos usuários (aqui referidos como "você" ou "seu") que utilizam o nosso site (www.mercadounido.com.br) e serviços relacionados. Esta política de privacidade explica como coletamos, usamos, compartilhamos e protegemos as informações pessoais que coletamos de você</p>
            <h1 className='font-bold pt-[40px] pb-[20px]'>Cookiees</h1>
            <p className='pb-[30px]'>O site do Mercado Unido usa cookies para melhorar a experiência do usuário. Cookies são arquivos de texto que são colocados no navegador do usuário para armazenar informações sobre a navegação na web, como preferências e histórico de compras. Essas informações podem incluir dados pessoais, como nome, endereço de e-mail e informações de pagamento.</p>
            <p className='pb-[30px]'>Os cookies são usados para melhorar o desempenho do site, fornecer conteúdo personalizado, analisar a atividade do usuário e facilitar as transações de compras. Eles também são usados para fins de publicidade direcionada, com base nos interesses do usuário.</p>
            <p className='pb-[30px]'>Os usuários têm a opção de aceitar ou recusar o uso de cookies no site do Mercado Unido. Se o usuário optar por não aceitar cookies, algumas partes do site podem não funcionar corretamente.</p>
            <p>Ao usar o site do Mercado Unido, o usuário concorda com o uso de cookies de acordo com esta política de privacidade. O usuário pode alterar suas preferências de cookies a qualquer momento, através das configurações do navegador.</p>
            <h1 className='font-bold pt-[40px] pb-[20px]'>Coleta de informações</h1>
            <p>Nós coletamos as informações que você nos fornece quando se cadastra em nosso site, incluindo seu nome completo, foto, número de CPF, endereço de e-mail, número de celular, data de nascimento e informações referentes aos seus endereços. Também coletamos informações quando você preenche o local de entrega e a forma de pagamento, incluindo o endereço de cobrança e o endereço de entrega. Durante a análise e o monitoramento de suas compras ou outras transações financeiras, coletamos informações cadastrais, tipo de produto, quantidade, valor da mercadoria (unitário), valor do frete, valor total da compra ou transação, natureza da transação financeira e outras informações relevantes.</p>
            <h1 className='font-bold pt-[40px] pb-[20px]'>Uso das informações</h1>
            <p>Usamos as informações pessoais que coletamos para melhorar sua experiência em nosso site e para enviar ofertas, produtos e informações relevantes sobre nossos serviços. Também podemos usar suas informações para fins de marketing, análise de dados e personalização de conteúdo. Compartilhamos suas informações com fornecedores de serviços de entrega para garantir que seus produtos sejam entregues com segurança e eficiência.</p>
            <h1 className='font-bold pt-[40px] pb-[20px]'>Proteção de informações</h1>
            <p>Tomamos medidas razoáveis para proteger suas informações pessoais contra perda, roubo e uso indevido, bem como para proteger suas informações contra acesso, divulgação, alteração e destruição não autorizados. Usamos medidas de segurança administrativas, físicas e técnicas para proteger suas informações pessoais, incluindo proteção contra acesso não autorizado a sistemas de processamento de dados e proteção contra ataques cibernéticos. Além disso, todas as informações confidenciais são transmitidas via SSL e são armazenadas em nossos servidores com acesso restrito.</p>
            <h1 className='font-bold pt-[40px] pb-[20px]'>Seus direitos</h1>
            <p>Você tem o direito de acessar, corrigir, excluir ou limitar o processamento de suas informações pessoais. Você pode corrigir seus dados e excluir sua conta em nosso sistema entrando em contato conosco por meio do e-mail atendimento@mercadounido.com.br. Se você tiver alguma dúvida ou preocupação sobre nossas práticas de privacidade, entre em contato conosco por meio desse mesmo endereço de e-mail.</p>
            <h1 className='font-bold pt-[40px] pb-[20px]'>Alteração nesta política de privacidade</h1>
            <p className='pb-[30px]'>Podemos alterar esta política de privacidade de tempos em tempos. Recomendamos que você verifique esta página regularmente para se manter informado sobre as alterações. Se fizermos alterações significativas nesta política de privacidade, informaremos você por meio de um aviso em destaque em nosso site ou por e-mail.</p>
          </p>
          <p className='font-bold text-[14px] text-dark-gray'>Última atualização: 10/03/2023</p>
        </article>
      </section>
      <Footer />
    </>
    
  )
}

export default  PrivacyPolicies;