import React from 'react';
import { AiOutlineArrowDown } from "react-icons/ai";

export default function DownSaleTag({ down }) {
  return (
    <div className="bg-green text-white rounded-full flex flex-row items-center justify-center py-[6px] px-[12px]">
      <AiOutlineArrowDown color="white" />
      <p className='px-1 text-[12px]'>
        {
          (Math.round(parseFloat(down)) / 100).toLocaleString(undefined, { style: 'percent' })
        }
      </p>
    </div>
)
}