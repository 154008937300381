import React, { useContext, useState } from 'react';
import { Link } from "react-router-dom";
import AuthContext from '../context/AuthContext';
import { Toast } from './../utils/toast';
import Loader from './small_components/Loader';

function ProfileMenu() {
  const { signOut, token, shoppingCart } = useContext(AuthContext);

  const [isLoading, setIsLoading] = useState(false);

  async function handleClickSignOut(e) {
    e.preventDefault();
    setIsLoading(true);

    try {
      const response = await signOut(token);

      if(!response) {
        Toast.fire({
          icon: 'error',
          title: 'NÃO FOI POSSÍVEL TE DESCONECTAR.'
        })

        return;
      }

      Toast.fire({
        icon: 'success',
        title: 'VOCÊ FOI DESLOGADO'
      })
    } catch (error) {
      Toast.fire({
        icon: 'error',
        title: 'NÃO FOI POSSÍVEL TE DESCONECTAR.'
      })
    } finally {
      setIsLoading(true);
    }
  }

  return (
    <>
      {
        (isLoading) && (
          <Loader />
        )
      }
      <section id="side-menu" className="ease-in-out absolute top-28 lg:top-12 -right-1 lg:right-0 w-40 z-50 bg-white pl-4 py-4 flex flex-col text-black shadow-md rounded-md duration-150 text-left">
        <nav className="flex flex-col text-lg lg:text-md">
          <Link to="/orders" className="py-1 hover:text-primary-blue duration-150">
            Meus Pedidos
          </Link>
          <Link to="/cart" className="py-1 hover:text-primary-blue duration-150 flex justify">
            <span>Meu Carrinho</span>
            <p style={{ backgroundColor: '#770e0e' }} class="flex h-2 w-2 items-center justify-center rounded-full bg-500 p-3 text-xs text-white">{shoppingCart.length}</p>
          </Link>
          <Link to="/profile" className="py-1 hover:text-primary-blue duration-150">
            Meu Perfil
          </Link>
          <a href="/" onClick={(e) => handleClickSignOut(e)} className="py-1 hover:text-primary-blue duration-150">
            Sair
          </a>
        </nav>
      </section>
    </>
  )
}

export default ProfileMenu;