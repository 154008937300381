import React from 'react';
import { Link } from 'react-router-dom';
import OfferCard from './OfferCard';
import '../pages/css/ProductPage.css';
import { useContext } from 'react';
import MainContext from '../context/MainContext';

function SaleSection({ category, title }) {
  const { setSelectedOffer, offers } = useContext(MainContext);

  return (
    <div className="py-8 mt-2 max-w-[1280px] mx-auto">
      <h2 className="text-dark-gray font-bold text-2xl mx-auto text-center px-4 py-8">
        {title}
      </h2>
      <div className='relative flex items-center flex-row ml-2'>
        <div className='card-section w-full h-full overflow-x-scroll scroll whitespace-nowrap scroll-smooth'>
          {
            offers.map((offer) => {
              const newTotalRate = offer.rate.reduce((total, review) => {
                return total + parseFloat(review.rate);
              }, 0);

              return (
                <Link key={offer.id} to={`/product/${offer.id}`}>
                  <OfferCard
                    onClick={() =>
                      setSelectedOffer(offer)
                    }
                    imgSrc={offer.image[0].urlImg}
                    name={offer.product.name}
                    retailPrice={offer.retailPrice}
                    price={offer.price}
                    down={offer.down}
                    missingUnits={offer.missingUnits}
                    neededUnities={offer.neededUnities}
                    offerRemainingDays={offer.offerRemainingDays}
                    offerRemainingTime={offer.offerRemainingTime}
                    firstPurchase={offer.firstPurchase}
                    expiration={offer.expiration}
                    rate={parseFloat(newTotalRate) / offer.rate.length}
                  />
                </Link>
              )
            })
          }
        </div>
      </div>
    </div>
  )
}

export default SaleSection;