import React, { useContext, useState } from 'react';
import MainContext from '../../context/MainContext';

export default function AvatarChange () {
  const {avatar, setAvatar} = useContext(MainContext);
  const [avatarUrl, setAvatarUrl] = useState(null);

  const handleFileChange = (e) => {
    setAvatarUrl(URL.createObjectURL(e.target.files[0]));
    setAvatar(e.target.files[0]);
  };

  return (
    <div className="flex items-center justify-center pt-[30px]">
      <span className="h-auto w-[80px] lg:w-[120px] overflow-hidden rounded-full bg-gray-100">
        {avatarUrl ? (
          <img src={avatarUrl} alt="Avatar" className="h-full w-full" />
        ) : (
          <svg className="h-full w-full text-gray-300" fill="currentColor" viewBox="0 0 24 24">
            <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
          </svg>
        )}
      </span>
      <label
        htmlFor="file-upload"
        className="relative cursor-pointer font-bold text-[16px] border-[2px] border-gray-500 bg-white rounded-md px-[28px] py-1 text-primary-blue duration-150 opacity-60 mx-[12px]"
      >
        <span>Adicionar foto</span>
        <input
          id="file-upload"
          name="file-upload"
          type="file"
          className="sr-only"
          onChange={handleFileChange}
        />
      </label>
    </div>
  );
}