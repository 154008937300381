import React, { useContext, useState } from 'react';
import Footer from '../components/Footer';
import Header from '../components/Header';
import { AiOutlineArrowLeft } from "react-icons/ai";
import { useNavigate } from 'react-router-dom';
import { formatMoney, formatDate, isOfferExpiration } from '../utils/format';
import AuthContext from '../context/AuthContext';
import MainContext from '../context/MainContext';
import Loader from '../components/small_components/Loader';
import emptyCart from './../assets/icons/empty_cart.png';

export default function MyCart() {
    const { shoppingCart, deleteItemCart, setIdCart } = useContext(AuthContext);

    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);

    const deleteItem = async (id) => {
        setIsLoading(true);

        try {
            const response = await deleteItemCart(id);

            if (response) {
                return true;
            }
        } catch (error) {
            console.log(error);
        } finally {
            setIsLoading(false);
        }
    }

    return (
        <>
            <Header />
            <section className="bg-background-gray py-10">
                {
                    (isLoading) && (
                        <Loader />
                    )
                }
                {/* =======@ DESKTOP @======= */}
                <article className='bg-white max-w-[862px] py-[30px] rounded-xl shadow-md mx-auto mb-[40px] px-[30px] hidden lg:block'>
                    <div className='flex flex-row items-center hover:cursor-pointer hover:opacity-80' onClick={() => navigate(-1)}>
                        <AiOutlineArrowLeft className='text-primary-blue font-bold' />
                        <p className='px-2 text-primary-blue underline'>Voltar</p>
                    </div>
                    <h3 className="text-primary-blue text-left w-full font-bold text-[35px]">Meu Carrinho</h3>
                    {
                        (shoppingCart.length === 0) && (
                            <div className='row-span-1 col-span-3 bg-white rounded-xl flex justify-center items-center mx-auto w-full mt-[30px]'>
                                <div className='flex-column justify-center items-center'>
                                    <img src={emptyCart} className='w-[280px]' alt="cart_img" />
                                    <h1 className='text-center text-primary-red font-bold text-[22px]'>Seu Carrinho Está Vazio!</h1>
                                </div>
                            </div>
                        )
                    }
                    {
                        shoppingCart.map(item => {
                            return (
                                <div className='row-span-1 col-span-3 bg-white rounded-xl shadow-md mx-auto w-full mt-[30px]'>
                                    <div className='rounded-t-xl bg-background-gray border-[1px] h-[50px] w-full flex items-center justify-between'>
                                        <h2 className='text-primary-red text-left px-[39px] font-bold'>{'Adicionado em ' + formatDate(item.created_at)} {(item.offer.expiration !== null) ? '(Oferta expirada)' : ''}</h2>
                                        <h2 className='text-primary-red text-left px-[39px] font-bold cursor-pointer' onClick={(e) => deleteItem(item.id)}>
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
                                            </svg>
                                        </h2>
                                    </div>
                                    <aside className='py-[30px] text-left px-[30px] pr-[60px] text-[14px] text-gray-700 flex flex-row items-center justify-between w-full cursor-pointer'>
                                        <div className='flex flex-row items-center justify-between pb-[15px]'>
                                            <img className='max-w-[120px]' src={item.offer.product.images[0].urlImg} alt="" />
                                            <p className='font-bold px-[25px]'>{item.quantity + "x " + item.offer.title}</p>
                                        </div>
                                        <div className='flex flex-col items-start justify-between pb-[15px]'>
                                            <p className='font-bold'>Valor</p>
                                            <p className=''>{formatMoney(item.price)}</p>
                                        </div>
                                    </aside>
                                    <div className='rounded-b-xl bg-background-gray border-[1px] h-[50px] w-full flex items-center justify-end'>
                                        <div
                                            className={`
                                            ${(item.offer.firstPurchase !== null) &&
                                                (isOfferExpiration(item.offer.firstPurchase, item.offer.offerRemainingDays, item.offer.offerRemainingTime).hasExpired) && 'cursor-not-allowed opacity-50'
                                                } bg-green text-white rounded-xl 
                                              ${(item.offer.firstPurchase === null)
                                                    ? 'hover:opacity-80 duration-150'
                                                    : (!isOfferExpiration(item.offer.firstPurchase, item.offer.offerRemainingDays, item.offer.offerRemainingTime).hasExpired) && 'hover:opacity-80 duration-150'
                                                } flex flex-row items-center justify-start font-bold w-[300px] text-center py-[5px] 
                                              ${(item.offer.firstPurchase === null)
                                                    ? 'hover:cursor-pointer'
                                                    : (!isOfferExpiration(item.offer.firstPurchase, item.offer.offerRemainingDays, item.offer.offerRemainingTime).hasExpired) && 'hover:cursor-pointer'
                                                }
                                            `}
                                            onClick={(e) => {
                                                e.preventDefault();

                                                if (item.offer.firstPurchase === null || item.offer.firstPurchase !== null && !isOfferExpiration(item.offer.firstPurchase, item.offer.offerRemainingDays, item.offer.offerRemainingTime).hasExpired) {
                                                    setIdCart(item.id);
                                                    navigate(`/checkout/${item.id_offer}`)
                                                }
                                            }}
                                        >
                                            <p className='text-center w-full'>
                                                Finalizar Compra
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }
                </article>

                {/* =======@ MOBILE @======= */}
                <article className='bg-white py-[30px] rounded-xl shadow-md mb-[40px] px-[30px] mx-[16px] mb-[40px] lg:hidden'>
                    <div className='flex flex-row items-center hover:cursor-pointer hover:opacity-80' onClick={() => navigate(-1)}>
                        <AiOutlineArrowLeft className='text-primary-blue font-bold' />
                        <p className='px-2 text-primary-blue underline'>Voltar</p>
                    </div>
                    <h3 className="text-primary-blue text-left w-full font-bold text-[35px]">Meu Carrinho</h3>
                    {
                        (shoppingCart.length === 0) && (
                            <div className='row-span-1 col-span-3 bg-white rounded-xl flex justify-center items-center mx-auto w-full mt-[30px]'>
                                <div className='flex-column justify-center items-center'>
                                    <img src={emptyCart} className='w-[280px]' alt="cart_img" />
                                    <h1 className='text-center text-primary-red font-bold text-[22px]'>Seu Carrinho Está Vazio!</h1>
                                </div>
                            </div>
                        )
                    }
                    {
                        shoppingCart.map(item => {
                            return (
                                <div className='row-span-1 col-span-3 bg-white rounded-xl shadow-md mx-auto w-full mt-[30px]'>
                                    <div className='rounded-t-xl bg-background-gray border-[1px] h-[50px] w-full flex items-center justify-between'>
                                        <h2 className='text-primary-red text-left px-[39px] font-bold'>{formatDate(item.created_at)} {(item.offer.expiration !== null) ? 'Oferta expirada' : ''}</h2>
                                        <h2 className='text-primary-red text-left px-[39px] font-bold cursor-pointer' onClick={(e) => deleteItem(item.id)}>
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                                                <path strokeLinecap="round" strokeLinejoin="round" d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
                                            </svg>
                                        </h2>
                                    </div>
                                    <aside className='py-[30px] text-left px-[30px] pr-[60px] text-[14px] text-gray-700  w-full cursor-pointer'>
                                        <div className='flex flex-row items-center justify-center pb-[30px]'>
                                            <img className='max-w-[120px]' src={item.offer.product.images[0].urlImg} alt="" />
                                        </div>
                                        <div className='flex flex-row items-center justify-center pb-[15px]'>
                                            <p className='font-bold'>{item.quantity + "x " + item.offer.title}</p>
                                        </div>
                                        <div className='flex flex-col items-center justify-center pb-[15px]'>
                                            <p className='font-bold'>Valor</p>
                                            <p className=''>{formatMoney(item.price)}</p>
                                        </div>
                                    </aside>
                                    <div className='rounded-b-xl bg-background-gray border-[1px] h-[50px] w-full flex items-center justify-end p-[10px]'>
                                        <div
                                            className={`
                                            ${(item.offer.firstPurchase !== null) &&
                                                (isOfferExpiration(item.offer.firstPurchase, item.offer.offerRemainingDays, item.offer.offerRemainingTime).hasExpired) && 'cursor-not-allowed opacity-50'
                                                } bg-green text-white rounded-xl 
                                              ${(item.offer.firstPurchase === null)
                                                    ? 'hover:opacity-80 duration-150'
                                                    : (!isOfferExpiration(item.offer.firstPurchase, item.offer.offerRemainingDays, item.offer.offerRemainingTime).hasExpired) && 'hover:opacity-80 duration-150'
                                                } flex flex-row items-center justify-start font-bold w-full text-center py-[5px] 
                                              ${(item.offer.firstPurchase === null)
                                                    ? 'hover:cursor-pointer'
                                                    : (!isOfferExpiration(item.offer.firstPurchase, item.offer.offerRemainingDays, item.offer.offerRemainingTime).hasExpired) && 'hover:cursor-pointer'
                                                }
                                            `}
                                            onClick={(e) => {
                                                e.preventDefault();

                                                if (item.offer.firstPurchase === null || item.offer.firstPurchase !== null && !isOfferExpiration(item.offer.firstPurchase, item.offer.offerRemainingDays, item.offer.offerRemainingTime).hasExpired) {
                                                    setIdCart(item.id);
                                                    navigate(`/checkout/${item.id_offer}`)
                                                }
                                            }}
                                        >
                                            <p className='text-center w-full'>
                                                Finalizar Compra
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }
                </article>
            </section >
            <Footer />
        </>
    )
}