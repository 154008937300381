import React from 'react';
import { Link, useNavigate } from 'react-router-dom';

function ProfileLoggedOffDesktop(){
  const navigate = useNavigate();

  return (
    <div className="pl-2 flex flex-row">
      <button onClick={() => navigate('/login')} className="mx-1 font-bold text-[14px] border border-white bg-primary-red rounded-lg px-[27px] py-1 h-[38px] text-white hover:bg-tertiary-red hover:border-tertiary-red duration-150">
        Entrar
      </button>
      <Link to='/create-account'>
        <button onClick={() => navigate('/create-account')} className="mx-1 font-bold text-[14px] border border-white bg-white rounded-lg px-[27px] py-1 h-[38px] text-primary-blue hover:bg-secondary-blue hover:text-white hover:border-secondary-blue duration-150 min-w-[150px]">
          Criar conta
        </button>
      </Link>
    </div>
  )
}

export default ProfileLoggedOffDesktop;