import React, { useState, useEffect } from 'react';
import { getCurrentTimestamp } from './../utils/format';

function Timer(props) {
  var timeSplitArray = props.time.split(':');

  const [remainingTime, setRemainingTime] = useState(null);

  function calculateRemainingTime() {
    const currentTime = new Date().getTime();
    const targetTime = new Date((props.firstPurchase === null) ? '2023-08-17 08:26:45' : props.firstPurchase).getTime() + (props.days * 24 + parseInt(props.time.split(':')[0])) * 60 * 60 * 1000 + parseInt(props.time.split(':')[1]) * 60 * 1000 + parseInt(props.time.split(':')[2]) * 1000;
    return Math.max(targetTime - currentTime, 0);
  }

  useEffect(() => {
    const interval = setInterval(() => {
      setRemainingTime(calculateRemainingTime());
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  const days = Math.floor(remainingTime / (24 * 60 * 60 * 1000));
  const hours = Math.floor((remainingTime % (24 * 60 * 60 * 1000)) / (60 * 60 * 1000));
  const minutes = Math.floor((remainingTime % (60 * 60 * 1000)) / (60 * 1000));
  const seconds = Math.floor((remainingTime % (60 * 1000)) / 1000);

  return (
    <div className='flex flex-col items-center'>
      <article className='grid grid-cols-4 items-center p-2 rounded-md bg-primary-red my-4 w-full'>
        {
          (props.firstPurchase === null) ? (
            <>
              <div className='flex flex-col items-center justify-center'>
                <h3 className='text-[25px] text-white font-medium'>{props.days}</h3>
                <p className='text-white text-sm'>Dias</p>
              </div>
              <div className='flex flex-col items-center justify-center'>
                <h3 className='text-[25px] text-white font-medium'>{timeSplitArray[0]}</h3>
                <p className='text-white text-sm'>Horas</p>
              </div>
              <div className='flex flex-col items-center justify-center'>
                <h3 className='text-[25px] text-white font-medium'>{timeSplitArray[1]}</h3>
                <p className='text-white text-sm'>Minutos</p>
              </div>
              <div className='flex flex-col items-center justify-center'>
                <h3 className='text-[25px] text-white font-medium'>{timeSplitArray[2]}</h3>
                <p className='text-white text-sm'>Segundos</p>
              </div>
            </>
          ) : (
            <>
              <div className='flex flex-col items-center justify-center'>
                <h3 className='text-[25px] text-white font-medium'>{days}</h3>
                <p className='text-white text-sm'>Dias</p>
              </div>
              <div className='flex flex-col items-center justify-center'>
                <h3 className='text-[25px] text-white font-medium'>{hours}</h3>
                <p className='text-white text-sm'>Horas</p>
              </div>
              <div className='flex flex-col items-center justify-center'>
                <h3 className='text-[25px] text-white font-medium'>{minutes}</h3>
                <p className='text-white text-sm'>Minutos</p>
              </div>
              <div className='flex flex-col items-center justify-center'>
                <h3 className='text-[25px] text-white font-medium'>{seconds}</h3>
                <p className='text-white text-sm'>Segundos</p>
              </div>
            </>
          )
        }
      </article >
    </div >
  )
}

function SmallTimer(props) {
  var timeSplitArray = props.time.split(':');

  const [remainingTime, setRemainingTime] = useState(calculateRemainingTime());

  function calculateRemainingTime() {
    const currentTime = new Date().getTime();
    const targetTime = new Date((props.firstPurchase === null) ? '2023-08-17 08:26:45' : props.firstPurchase).getTime() + (props.days * 24 + parseInt(props.time.split(':')[0])) * 60 * 60 * 1000 + parseInt(props.time.split(':')[1]) * 60 * 1000 + parseInt(props.time.split(':')[2]) * 1000;
    return Math.max(targetTime - currentTime, 0);
  }

  useEffect(() => {
    const interval = setInterval(() => {
      setRemainingTime(calculateRemainingTime());
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  const days = Math.floor(remainingTime / (24 * 60 * 60 * 1000));
  const hours = Math.floor((remainingTime % (24 * 60 * 60 * 1000)) / (60 * 60 * 1000));
  const minutes = Math.floor((remainingTime % (60 * 60 * 1000)) / (60 * 1000));
  const seconds = Math.floor((remainingTime % (60 * 1000)) / 1000);

  return (
    <div className='flex flex-col items-center'>
      <h2 className='font-bold text-primary-red'>
        Tempo restante
      </h2>
      <article className='grid grid-cols-4 items-center p-1 rounded-md bg-primary-red my-4 w-full'>
        {
          (props.firstPurchase === null) ? (
            <>
              <div className='col-span-1'>
                <h3 className='text-white font-medium'>{props.days}</h3>
                <p className='text-white text-[10px]'>D</p>
              </div>
              <div className='col-span-1 flex flex-col items-center justify-center'>
                <h3 className='text-white font-medium'>{timeSplitArray[0]}</h3>
                <p className='text-white text-[10px]'>H</p>
              </div>
              <div className='col-span-1 flex flex-col items-center justify-center'>
                <h3 className='text-white font-medium'>{timeSplitArray[1]}</h3>
                <p className='text-white text-[10px]'>M</p>
              </div>
              <div className='col-span-1 flex flex-col items-center justify-center'>
                <h3 className='text-white font-medium'>{timeSplitArray[2]}</h3>
                <p className='text-white text-[10px]'>S</p>
              </div>
            </>
          ) : (
            <>
              <div className='col-span-1'>
                <h3 className='text-white font-medium'>{days}</h3>
                <p className='text-white text-[10px]'>D</p>
              </div>
              <div className='col-span-1 flex flex-col items-center justify-center'>
                <h3 className='text-white font-medium'>{hours}</h3>
                <p className='text-white text-[10px]'>H</p>
              </div>
              <div className='col-span-1 flex flex-col items-center justify-center'>
                <h3 className='text-white font-medium'>{minutes}</h3>
                <p className='text-white text-[10px]'>M</p>
              </div>
              <div className='col-span-1 flex flex-col items-center justify-center'>
                <h3 className='text-white font-medium'>{seconds}</h3>
                <p className='text-white text-[10px]'>S</p>
              </div>
            </>
          )
        }
      </article>
    </div>
  )
}

export {
  Timer, SmallTimer
}