import React, { useState, useEffect, useContext } from 'react';
import DownSaleTag from './small_components/DownSaleTag';
import Rating from './small_components/Rating';
import { formatFistPurchaseForTimer, formatMoney } from '../utils/format';
import { SmallTimer } from './Timer';

function OfferCard({
  imgSrc,
  name,
  retailPrice,
  price, down,
  missingUnits,
  neededUnities,
  offerRemainingDays,
  offerRemainingTime,
  firstPurchase,
  expiration,
  rate,
  onClick
}) {
  const dividerStyle = {
    backgroundColor: '#707070',
    height: '1.25px',
    marginTop: '20px',
    marginBottom: '20px',
  }
  
  return (
    <div onClick={onClick} className="bg-white mx-4 my-2 w-[286px] h-[738px] rounded-xl hover:shadow-md px-[44px] py-[23px] text-center inline-block duration-150">
      <img className="w-[198px] h-[148px] object-cover" src={ imgSrc } alt="product test" />
      <p className="text-[15px] pt-[31px] truncate">{ name } </p>
      <div className='py-[15px] flex justify-center'>
        <Rating propRating={rate.toFixed(1)} />
      </div>
      <div className='text-center flex flex-col items-center'>
        <p className="text-[15px] text-dark-gray">Preço no varejo</p>
        <p className="font-bold text-[16px] text-red-500 line-through pb-[16px]">{formatMoney(retailPrice)}</p>
        <DownSaleTag down={ down } />
        <p className="font-bold text-green text-[30px] pt-[11px] pb-[2px]">{ formatMoney(price) }</p>
        <span className="text-dark-gray text-xs pb-[22px]">10x de {formatMoney(parseInt(price) / 10)} sem juros</span>
      </div>
      <hr style={ dividerStyle }/>
      <div className='text-center'>
        <p className='pt-[5px] text-[22px] font-bold text-yellow-400'>Faltam { missingUnits }</p>
        <p className='font-bold text-dark-gray text-[18px] pt-[22px]'>de {neededUnities} unidades</p>
        <p className='text-[14px] text-dark-gray pb-[20px]'>Para concretizar negócio</p>
      </div>
      <SmallTimer 
        days={offerRemainingDays}
        time={offerRemainingTime}
        firstPurchase={(firstPurchase === null) ? null : formatFistPurchaseForTimer(firstPurchase)}
        expiration={expiration}
      />
    </div>
  )
}

export default OfferCard;