import React from 'react';
import { useContext } from 'react';
import MainContext from '../../context/MainContext';
import { BsCheck } from "react-icons/bs";

export default function Steps() {
  const { createAccStep } = useContext(MainContext);

  return (
    <nav aria-label="Progress">
      {
        createAccStep === 1 ? (
          <ol className="flex items-center">
            <li className='relative pr-8 sm:pr-[12px]'>            
              <div className="absolute inset-0 flex items-center" aria-hidden="true">
                <div className="h-0.5 w-full bg-primary-blue" />
              </div>
              <a
                href="/create-account/1"
                className="relative flex text-[22px] h-[35px] w-[35px] items-center justify-center rounded-full bg-primary-red pointer-events-none font-bold text-white px-[11px] py-[5px]"
              >
                1
              </a>
            </li>
            <li className='relative'>            
              <div className="absolute inset-0 flex items-center" aria-hidden="true">
                <div className="h-0.5 w-full bg-primary-blue" />
              </div>
              <a
                href="/create-account/1"
                className="relative flex text-[22px] h-[35px] w-[35px] items-center justify-center rounded-full bg-gray-200 pointer-events-none font-bold text-white px-[11px] py-[5px]"
              >
                2
              </a>
            </li>
          </ol>
        ) : (
          <ol className="flex items-center">
            <li className='relative pr-8 sm:pr-[12px]'>            
              <div className="absolute inset-0 flex items-center" aria-hidden="true">
                <div className="h-0.5 w-full bg-primary-blue" />
              </div>
              <a
                href="/create-account/1"
                className="relative flex text-[22px] h-[35px] w-[35px] items-center justify-center rounded-full bg-primary-blue pointer-events-none font-bold text-white"
              >
                <BsCheck />
              </a>
            </li>
            <li className='relative'>            
              <div className="absolute inset-0 flex items-center" aria-hidden="true">
                <div className="h-0.5 w-full bg-primary-blue" />
              </div>
              <a
                href="/create-account/1"
                className="relative flex text-[22px] h-[35px] w-[35px] items-center justify-center rounded-full bg-primary-red pointer-events-none font-bold text-white px-[11px] py-[5px]"
              >
                2
              </a>
            </li>
          </ol>
        )
      }
    </nav>
  )
}
