import React from 'react';

function Button ({ text, color, icon, width, fontSize, onClick, font }) {

  const style = {
    backgroundColor: color,
    width: width,
    fontSize: fontSize,
    fontWeight: font,
  }

  return (
    <button onClick={onClick} style={ style } className={`bg-primary-blue text-white pl-[24px] py-[10px] rounded-xl hover:opacity-80 duration-150 flex flex-row items-center justify-start `}>
      { icon }
      { text }
    </button>
  )
};

export default Button;